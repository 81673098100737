import { Vue, prop } from 'vue-class-component';

import './accordion.scss';

type Mode = 'standard' | 'secondary' | 'frameless' | 'flat' | 'borderless';
type Color = 'accent' | 'primary' | 'grey' | 'white' | 'black' | 'danger';
type Size = 'md';

class Props {
  autoExpanded = prop({ default: false });
  titleMode = prop<Mode>({ default: 'frameless' });
  mode = prop<Mode>({ default: 'standard' });
  titleColor = prop<Color>({ default: 'black' });
  size = prop<Size>({ default: 'md' });
}

const CN = 'accordion';

export default class Accordion extends Vue.with(Props) {
  private expanded = false;

  created() {
    this.expanded = this.autoExpanded;
  }

  public collapse() {
    this.expanded = false;
  }

  render() {
    const cn = [
      CN,
      `${CN}--${this.mode}`,
      `${CN}--${this.size}`,
      this.expanded ? `${CN}--expanded` : '',
    ];
    return (
      <div class={cn}>
        <div class={`${CN}__summary`}>
          <r-button
            class={`${CN}__title-btn`}
            color={this.titleColor}
            icon="arrow-down"
            mode={this.titleMode}
            onclick={this.toggle}
          >
            {this.$slots.summary?.()}
          </r-button>
        </div>
        {this.expanded ? this.renderExpandedDetails() : this.renderCollapsedDetails()}
      </div>
    );
  }

  private renderExpandedDetails() {
    return <div class={`${CN}__details`}>{this.$slots.default?.()}</div>;
  }

  private renderCollapsedDetails() {
    return (
      <div class={`${CN}__collapsed-details`}>
        {this.$slots.collapsed?.()}
        <r-button
          class={`${CN}__collapsed-btn`}
          color="primary"
          mode="frameless"
          onclick={this.toggle}
          size="sm"
        >
          <l10n group="common" tkey="accordion__more" />
        </r-button>
      </div>
    );
  }

  private toggle() {
    this.expanded = !this.expanded;
  }
}
