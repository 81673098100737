// TODO: REMOVE THIS
// Deprecated! Do not use this component anymore.
// Since myacc does not have grid, keeping this component
// in shared package make no sense
// After deleting groups package this component and it's
// usage in ui-kit/index.ts files will be removed
// --------------------------------------------------------
import { $Props } from '@ravnur/core/typings/tsx';
import { Vue, Options, prop } from 'vue-class-component';

import { ThumbnailableEntity } from '../../types/Entity';
import Paper from '../paper/paper';
import Thumbnail from '../thumbnail/thumbnail';

import './grid-card.scss';

const CN = 'grid-card';

class Props {
  entity: ThumbnailableEntity;
  title = prop({ default: '' });
  showType = prop({ default: true });
  useOverlay = prop({ default: true });
}

@Options({
  emits: [],
})
export default class GridCard extends Vue.with(Props) {
  declare $props: $Props<Props> & Partial<HTMLDivElement>;

  render() {
    const cn = { [CN]: true, [`${CN}--overlayable`]: this.useOverlay };
    const attrs = { ...this.$attrs, tabindex: 0 };

    return (
      <Paper class={cn} force={false} {...attrs}>
        <Thumbnail
          class={`${CN}__thumbnail`}
          entity={this.entity}
          showType={this.showType}
          v-slots={{ default: this.$slots.thumbnail }}
        />
        <h4 class={`${CN}__title`} title={this.title}>
          {this.title}
        </h4>
        <div class={`${CN}__overlay`}>{this.$slots.overlay?.()}</div>
      </Paper>
    );
  }
}
