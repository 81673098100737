import { PortalSettings } from '@ravnur/shared/types/PortalSettings';
import genericRouteGuard from '@ravnur/shared/route-guard/route-guard';
import acls from '@ravnur/shared/helpers/acls';
import { instance as storeInstance } from '@/store';
import { UserPermissionsHelper } from '@ravnur/shared/helpers/acls/permissions';

function routeGuard(
  settings?: Array<keyof PortalSettings>,
  permissions?: Array<keyof UserPermissionsHelper>
) {
  return genericRouteGuard({
    settings,
    permissions,
    getSettingsStore: () => acls.getApplicationSettings(),
    getPermissionsStore: () => acls.getUserPermissions(),
    getStoreInitializedField: () => storeInstance.user.isAuth,
  });
}

export default routeGuard;
