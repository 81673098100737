import { Job, isRunningJob } from '@ravnur/shared/types/Job';
import { State, Getter, Mutation, Action } from 'vuex-simple';
import { Job$Response } from '@ravnur/shared/src/types/Job';

import JobsRepository from '@/repositories/jobs-repository';
import MyMediaRepository from '@/repositories/my-media-repository';

import { RooStore } from '../store';

const repository = new JobsRepository();
const myMediaRepository = new MyMediaRepository();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const keyByMediaId = (jobs: Job[], filter = (j: Job) => true) => {
  const grouped: Record<string, Job[]> = {};

  jobs?.filter(filter).forEach((j) => {
    grouped[j.mediaId] = grouped[j.mediaId] ? [...grouped[j.mediaId], j] : [j];
  });

  return grouped;
};

const runningByMediaId = (jobs: Job[]) => {
  return keyByMediaId(jobs, isRunningJob);
};

const JOBS_PING_TIMEOUT = 10000;

export class JobsModule {
  constructor(private root: RooStore) {}

  @State()
  public list: Job[] = [];

  @Getter()
  public get all(): Record<string, Job[]> {
    return keyByMediaId(this.list);
  }

  @Getter()
  public get running() {
    return runningByMediaId(this.list);
  }

  @Mutation()
  public addToList(job: Job) {
    this.list.push(job);
  }

  @Mutation()
  public removeFromList(jobId: string) {
    this.list = this.list.filter((j) => j.id !== jobId);
  }

  @Mutation()
  public updateList(list: Job[]) {
    this.list = list;
  }

  @Action()
  public async add(jobId: string) {
    try {
      const job = await repository.get(jobId);
      this.addToList(job);
      return job;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  @Action()
  async cancel({ mediaId, jobId }: { mediaId: string; jobId: string }) {
    try {
      await myMediaRepository.cancelJobs(mediaId);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      this.removeFromList(jobId);
    }
  }

  @Action()
  async refresh() {
    try {
      const jobs = await repository.monitor();

      const formatted: Job[] = jobs.map((job: Job$Response) => ({
        id: job.id,
        mediaId: job.media.id,
        state: job.status,
        createdBy: job.createdBy,
        progress: job.progress,
        type: job.type,
      }));

      this.updateList(formatted);

      return jobs;
    } catch (e) {
      console.log(e);
    }
  }

  @Action()
  async pulling() {
    try {
      await this.refresh();
    } catch (e) {
      console.log(e);
    }

    setTimeout(this.pulling, JOBS_PING_TIMEOUT);
  }

  @Action()
  update({ mediaId, job }: { mediaId: string; job: Job }) {
    const index = this.list.findIndex((j) => j.mediaId === mediaId);
    const newList = [...this.list];

    if (index !== -1) {
      newList[index] = {
        ...this.list[index],
        ...job,
      };
    }

    this.updateList(newList);
  }
}
