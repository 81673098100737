import { Prop } from '@ravnur/decorators';
import { Vue } from 'vue-class-component';

import './stats-btn.scss';

const ANIMATION_TIMEOUT = 300;
const CN = 'stats-btn';

const TYPES: Record<string, string | [string, string]> = {
  ratings: 'star',
  views: 'eye',
  likes: 'thumb-up',
  comments: 'chat',
  count: 'collections-bookmark',
  group: 'group',
  favorite: ['favorite-border', 'favorite'],
};

export default class StatsButton extends Vue {
  @Prop({ type: String, required: true }) statType: string;
  @Prop({ type: Boolean, required: true }) checked: boolean;
  @Prop({ type: Number, required: false }) count: Nullable<number>;
  @Prop({ type: String, default: 'md' }) size: 'md' | 'sm';
  @Prop({ type: String, default: 'black' }) color: 'black';

  private isAnimating = false;

  private get icon(): string {
    const type = TYPES[this.statType];
    if (type instanceof Array) {
      const [unchecked, checked] = type;
      return this.checked ? checked : unchecked;
    }
    return type;
  }

  private get currentColor(): 'primary' | 'black' {
    return this.checked ? 'primary' : this.color;
  }

  private toggle() {
    this.isAnimating = true;
    setTimeout(() => (this.isAnimating = false), ANIMATION_TIMEOUT);
    this.$emit('toggle');
  }

  render() {
    const cn = {
      CN: true,
      [`${CN}--animation`]: this.isAnimating,
      [`${CN}--checked`]: this.checked,
      [`${CN}--unchecked`]: !this.checked,
    };
    return (
      <r-button
        class={cn}
        color={this.currentColor}
        icon={this.icon}
        iconSize={this.size}
        mode="frameless"
        onclick={this.toggle}
        size={this.size}
      >
        {this.renderCountButton()}
      </r-button>
    );
  }

  private renderCountButton() {
    if (this.count) {
      return <span class={`${CN}__count`}>{this.count}</span>;
    }
  }
}
