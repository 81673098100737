import { PortalSettings } from '../../types/PortalSettings';

export class AggregatePortalSettings {
  constructor(private readonly settings: Nullable<PortalSettings>[]) {}

  get isAudios() {
    return this.isAvailableWherever('isAudios');
  }

  get isDisplayUserChannels() {
    return this.isAvailableWherever('isDisplayUserChannels');
  }

  get isGroups() {
    return this.isAvailableWherever('isGroups');
  }

  get isFaq() {
    return this.isAvailableWherever('isFaq');
  }

  get isSupport() {
    return this.isAvailableWherever('isSupport');
  }

  get isPhotos() {
    return this.isAvailableWherever('isPhotos');
  }

  get isSmComments() {
    return this.isAvailableWherever('isSmComments');
  }

  get isSmRating() {
    return this.isAvailableWherever('isSmRating');
  }

  get isSmRelated() {
    return this.isAvailableWherever('isSmRelated');
  }

  get isSmStatistics() {
    return this.isAvailableWherever('isSmStatistics');
  }

  get isSubscriptions() {
    return this.isAvailableWherever('isSubscriptions');
  }

  get isUploads() {
    return this.isAvailableWherever('isUploads');
  }

  get isVideos() {
    return this.isAvailableWherever('isVideos');
  }

  get isScreenCasterEnabled() {
    return this.isAvailableWherever('isScreenCasterEnabled');
  }

  get isSignoutEnabled() {
    return this.isAvailableWherever('isSignoutEnabled');
  }

  get isPrivacyPolicy() {
    return this.isAvailableWherever('isPrivacyPolicy');
  }

  get isTermSandConditions() {
    return this.isAvailableWherever('isTermSandConditions');
  }

  get isCategories() {
    return this.isAvailableWherever('isCategories');
  }

  get enableEmbededMediaCustomValidation() {
    return this.isAvailableWherever('enableEmbededMediaCustomValidation');
  }

  get isDocuments() {
    return this.isAvailableWherever('isDocuments');
  }

  get isCollections() {
    return this.isAvailableWherever('isCollections');
  }

  get isContactInfo() {
    return this.isAvailableWherever('isContactInfo');
  }

  get isUnlistedFeature() {
    return this.isAvailableWherever('isUnlistedFeature');
  }

  get isAdvancedSearch() {
    return this.isAvailableWherever('isAdvancedSearch');
  }

  get isExternalAccess() {
    return this.isAvailableWherever('isExternalAccess');
  }

  get isMediaRating() {
    return this.isAvailableWherever('isMediaRating');
  }

  get isMediaLikes() {
    return this.isAvailableWherever('isMediaLikes');
  }

  get isLiveStreaming() {
    return this.isAvailableWherever('isLiveStreaming');
  }

  get isEmailDomainManagement() {
    return this.isAvailableWherever('isEmailDomainManagement');
  }

  private isAvailableWherever(key: keyof PortalSettings): boolean {
    return this.settings.some((settings) => settings?.[key] ?? false);
  }
}
