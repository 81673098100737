import DisablingRouterLink from '@ravnur/shared/components/disabling-router-link/disabling-router-link';
import $t from '@ravnur/l10n/$t';
import { User$Counters } from '@ravnur/shared/types/User';
import { Options, setup, Vue } from 'vue-class-component';

import MyFavoritesFolders from '@/components/my-account/my-favorites-folders/my-favorites-folders';
import SidebarProfile from '@/components/my-account/sidebar/sidebar-profile';
import isMobile from '@ravnur/helpers/featured.detect/isMobile';
import usePortalSettings from '@/helpers/hooks/usePortalSettings';

import './sidebar.scss';
import { Inject } from '@ravnur/decorators';

const CN = 'sidebar';

type MyAccount$AccordionLink = Array<{
  route: string;
  tkey: string;
  countKey?: keyof User$Counters;
}>;

// RMP-1628 Hiding My Profile section, will be enabled again after v1 release
// const MY_ACCOUNT_LINKS: MyAccount$AccordionLink = [
//   {
//     route: 'ProfileSettings',
//     tkey: 'my-account__profile-settings',
//   },
//   {
//     route: '404',
//     tkey: 'my-account__email-preferences',
//   },
//   {
//     route: 'ChangePassword',
//     tkey: 'my-account__change-password',
//   },
// ];

@Options({
  components: {
    DisablingRouterLink,
    SidebarProfile,
  },
  watch: {
    collapsed: 'onCollapsedChanged',
  },
  emits: ['toggle'],
})
export default class Sidebar extends Vue {
  @Inject('to-admin-panel') private toAdminPanel: () => void;
  @Inject('is-admin') private isAdmin: boolean;

  private portalSettings = setup(() => usePortalSettings());

  private collapsed = false;
  private nestedMenuItems: { [key: string]: boolean } = {
    expandedFavorites: false,
    expandedFollowing: false,
    // RMP-1628 Removing MyProfile, will be enabled after v1 release
    // expandedMyAccount: false,
  };

  private get routeName() {
    return this.$route.name;
  }

  render() {
    const { counters } = this.store.user;

    return (
      <>
        <r-button
          class={`${CN}__mobile-btn`}
          color="black"
          icon="menu"
          mode="frameless"
          onclick={this.toggleSidebar}
        />
        <aside class={[`${CN}`, this.collapsed ? `${CN}--collapsed` : '']}>
          <div class={`${CN}__toggle-wrapper`}>
            <r-button
              class={`${CN}__toggle-desk`}
              color="black"
              icon="sidebar-toggle"
              mode="frameless"
              onclick={this.toggleSidebar}
            />
            <r-button
              class={`${CN}__toggle-mobile`}
              color="black"
              icon="close"
              mode="frameless"
              onclick={this.toggleSidebar}
            />
          </div>
          <SidebarProfile is-collapsed={this.collapsed} />
          <ul class={`${CN}__list`}>
            <li
              class={[`${CN}__list-item`, this.isActive(['MyVideos']) ? 'active' : '']}
              onClick={() => this.toRoute('MyVideos')}
            >
              <div
                class={`${CN}__link`}
                title={this.collapsed ? '' : $t('header', 'my-account__my-videos')}
              >
                <icon class={`${CN}__list-item--icon`} type="my-videos" />
                <l10n class={`${CN}__link-name`} group="header" tkey="my-account__my-videos" />
                <span class={`${CN}__link-count`}>{counters?.videos ?? 0}</span>
              </div>
            </li>
            <li
              class={[`${CN}__list-item`, this.isActive(['MyAudios']) ? 'active' : '']}
              onClick={() => this.toRoute('MyAudios')}
            >
              <div
                class={`${CN}__link`}
                title={this.collapsed ? '' : $t('header', 'my-account__my-audios')}
              >
                <icon class={`${CN}__list-item--icon`} type="my-audios" />
                <l10n class={`${CN}__link-name`} group="header" tkey="my-account__my-audios" />
                <span class={`${CN}__link-count`}>{counters?.audios ?? 0}</span>
              </div>
            </li>
            <li
              class={[`${CN}__list-item`, this.isActive(['MyPlaylists']) ? 'active' : '']}
              onClick={() => this.toRoute('MyPlaylists')}
            >
              <div
                class={`${CN}__link`}
                title={this.collapsed ? '' : $t('header', 'my-account__my-playlists')}
              >
                <icon class={`${CN}__list-item--icon`} type="my-playlists" />
                <l10n class={`${CN}__link-name`} group="header" tkey="my-account__my-playlists" />
                <span class={`${CN}__link-count`}>{counters?.playlists ?? 0}</span>
              </div>
            </li>
            <li
              class={[
                `${CN}__list-item ${CN}__list-item--border`,
                this.isActive(['ViewingHistory']) ? 'active' : '',
              ]}
              onClick={() => this.toRoute('ViewingHistory')}
            >
              <div
                class={`${CN}__link`}
                title={this.collapsed ? '' : $t('header', 'my-account__viewing-history')}
              >
                <icon class={`${CN}__list-item--icon`} type="viewing-history" />
                <l10n
                  class={`${CN}__link-name`}
                  group="header"
                  tkey="my-account__viewing-history"
                />
              </div>
            </li>
            {this.renderMyFavorites()}
            {this.portalSettings?.isGroups && (
              <li
                class={[`${CN}__list-item`, this.isActive(['MyGroups']) ? 'active' : '']}
                onClick={() => this.toRoute('MyGroups')}
              >
                <div
                  class={`${CN}__link`}
                  title={this.collapsed ? '' : $t('header', 'my-account__my-groups')}
                >
                  <icon class={`${CN}__list-item--icon`} type="my-groups" />
                  <l10n class={`${CN}__link-name`} group="header" tkey="my-account__my-groups" />
                  <span class={`${CN}__link-count`}>{counters?.groups || 0}</span>
                </div>
              </li>
            )}
            <li
              class={[`${CN}__list-item`, this.isActive(['FollowedUsers']) ? 'active' : '']}
              onClick={() => this.toRoute('FollowedUsers')}
            >
              <div
                class={`${CN}__link`}
                title={this.collapsed ? '' : $t('header', 'my-account__my-subscriptions')}
              >
                <icon class={`${CN}__list-item--icon`} type="following" />
                <l10n
                  class={`${CN}__link-name`}
                  group="header"
                  tkey="my-account__my-subscriptions"
                />
                <span class={`${CN}__link-count`}>{counters?.followingsUsers || 0}</span>
              </div>
            </li>
            {this.isAdmin && (
              <li
                class={[`${CN}__list-item ${CN}__list-item--admin-panel`]}
                onClick={() => this.toAdminPanel()}
              >
                <div
                  class={`${CN}__link`}
                  title={this.collapsed ? '' : $t('header', 'my-account__admin-portal')}
                >
                  <icon class={`${CN}__list-item--icon`} type="shield-lock" />
                  <l10n class={`${CN}__link-name`} group="header" tkey="user-menu__admin-portal" />
                </div>
              </li>
            )}
            <li
              class={[`${CN}__list-item ${CN}__list-item--logout`]}
              onClick={() => this.store.logout()}
            >
              <div
                class={`${CN}__link`}
                title={this.collapsed ? '' : $t('header', 'my-account__log-out')}
              >
                <icon class={`${CN}__list-item--icon`} type="logout" />
                <l10n class={`${CN}__link-name`} group="header" tkey="my-account__log-out" />
              </div>
            </li>
            {/* RMP-1628 Hiding My Profile section, will be enabled again after v1 release */}
            {/* {this.renderNestedMenuItem(
              'expandedMyAccount',
              MY_ACCOUNT_LINKS,
              'my-account__my-profile',
              'my-profile'
            )} */}
          </ul>
        </aside>
      </>
    );
  }

  private onCollapsedChanged() {
    if (!this.collapsed) {
      this.nestedMenuItems['expandedFollowing'] = false;
      this.nestedMenuItems['expandedFavorites'] = false;
      // RMP-1628 Removing MyProfile, will be enabled after v1 release
      // this.nestedMenuItems['expandedMyAccount'] = false;
    } else {
      this.nestedMenuItems['expandedFavorites'] = this.isActive(['MyFavorites']);
      this.nestedMenuItems['expandedFollowing'] = this.isActive(['FollowedUsers']);
      // RMP-1628 Removing MyProfile, will be enabled after v1 release
      // this.nestedMenuItems['expandedMyAccount'] = this.isActive(['MyProfile']);
    }
  }

  private toggleNestedMenu(key: string) {
    if (!this.nestedMenuItems[key] && !this.collapsed) {
      this.toggleSidebar();
    }

    this.nestedMenuItems[key] = !this.nestedMenuItems[key];
  }

  private renderMyFavorites() {
    return (
      <li>
        <div
          class={[`${CN}__list-item`, this.isActive(['MyFavorites']) ? 'active' : '']}
          title={this.collapsed ? '' : $t('header', 'my-account__my-favorites')}
          onClick={() => this.toggleNestedMenu('expandedFavorites')}
        >
          <icon class={`${CN}__list-item--icon`} type="my-favorites" />
          <l10n class={`${CN}__link-name`} group="header" tkey="my-account__my-favorites" />
          {this.renderLinkArrow(this.nestedMenuItems['expandedFavorites'])}
        </div>
        <MyFavoritesFolders
          class={[`${CN}__list`, `${CN}__list--folders`]}
          v-show={this.nestedMenuItems['expandedFavorites']}
          onFolderSelect={this.onFolderSelect}
        />
      </li>
    );
  }

  private renderNestedMenuItem(
    expandedKey: string,
    nestedLinks: MyAccount$AccordionLink,
    title: string,
    iconName: string,
    hasBorder = false
  ) {
    const isActive = this.isActive(nestedLinks.map((l) => l.route));

    return (
      <li class={hasBorder ? `${CN}__list-item--border` : ''}>
        {this.renderMenuName(expandedKey, title, iconName, isActive)}
        <ul
          class={[`${CN}__list`, `${CN}__list--submenu`]}
          v-show={this.nestedMenuItems[expandedKey]}
        >
          {this.renderNestedLinks(nestedLinks)}
        </ul>
      </li>
    );
  }

  private renderNestedLinks(links: MyAccount$AccordionLink) {
    const { counters } = this.store.user;

    return links.map((l) => {
      return (
        <li
          key={l.tkey}
          class={[`${CN}__list-item`, this.isActive([l.route]) ? 'active--nested' : '']}
        >
          <div class={`${CN}__link`} onClick={() => this.toRoute(l.route)}>
            <l10n class={`${CN}__link-name`} group="header" tkey={l.tkey} />
            <span class={`${CN}__link-count`}>
              {l.countKey ? (counters as any)[l.countKey] || 0 : null}
            </span>
          </div>
        </li>
      );
    });
  }

  private renderMenuName(expandedKey: string, title: string, iconName: string, isActive: boolean) {
    return (
      <div
        class={[`${CN}__list-item`, isActive ? 'active' : '']}
        onClick={() => this.toggleNestedMenu(expandedKey)}
      >
        <icon class={`${CN}__list-item--icon`} type={iconName} />
        <l10n class={`${CN}__link-name`} group="header" tkey={title} />
        {this.renderLinkArrow(this.nestedMenuItems[expandedKey])}
      </div>
    );
  }

  private renderLinkArrow(isExpanded: boolean) {
    return (
      <icon
        class={[`${CN}__link-arrow`, isExpanded ? `${CN}__link-arrow--expanded` : '']}
        type="arrow-down"
      />
    );
  }

  onFolderSelect() {
    if (isMobile) {
      this.collapsed = false;
    }
  }

  private isActive(routeName: string[]) {
    if (!this.routeName) return false;

    return routeName.includes(this.routeName as string);
  }

  private toggleSidebar() {
    this.collapsed = !this.collapsed;
    this.$emit('toggle', this.collapsed);
  }

  private toRoute(routeName: string) {
    if (isMobile) {
      this.collapsed = false;
    }

    this.$router.push({ name: routeName });
  }

  private expandActiveRoute() {
    this.nestedMenuItems['expandedFavorites'] = this.isActive(['MyFavorites']);
    this.nestedMenuItems['expandedFollowing'] = this.isActive(['FollowedUsers']);
    // RMP-1628 Removing MyProfile, will be enabled after v1 release
    // this.nestedMenuItems['expandedMyAccount'] = this.isActive(['MyProfile']);
  }

  mounted() {
    this.expandActiveRoute();

    this.collapsed = Object.values(this.nestedMenuItems).some((v) => v);
  }
}
