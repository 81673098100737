import { Group } from '@ravnur/shared/types/Group';
import { Tag } from '@ravnur/shared/types/Tag';
import { AxiosRequestConfig } from 'axios';
import MockAdapter from 'axios-mock-adapter/types';
import { _generateGroup, _generateMockList, _generateTag, _generateUser } from './helper-fakers';
import { faker } from '@faker-js/faker';

const groups: Group[] = _generateMockList(10, _generateGroup);
const tags: Tag[] = _generateMockList(+faker.random.numeric(2), _generateTag);
const u: User[] = _generateMockList(+faker.random.numeric(2), _generateUser);

const users = u.map((u) => {
  return { id: u.id, name: u.displayName };
});

type LookupType = 'user' | 'group' | 'category' | 'tag';

type Media$Params = {
  Offset?: number;
  Count?: number;
  Q?: string;
};

type LookupEntity = {
  id: string;
  name: string;
};

export default function mocker(mock: MockAdapter) {
  mock.onGet(/\/api\/v1\.0\/lookup\/\w+\/\d+/).reply(_get);
  mock.onGet(/\/api\/v1\.0\/lookup\/\w+/).reply(_load);
}

function _load(config: AxiosRequestConfig) {
  const params: Media$Params = config.params;
  const url = config.url || '';
  const q = params.Q;
  const pathes = url.split('/');

  let res: Array<LookupEntity> = _data(pathes.pop() as LookupType);
  if (q) {
    res = res.filter((c) => c.name.indexOf(q) > -1);
  }

  const count = params.Count;
  const total: number = res.length;
  if (count) {
    const offset = params.Offset || 0;
    res = res.slice(offset, offset + count);
  }

  return [200, { count: total, items: res }];
}

function _get(config: AxiosRequestConfig) {
  const url = config.url || '';
  const pathes = url.split('/');
  const id = pathes.pop();
  const arr = _data(pathes.pop() as LookupType) || [];

  const [res] = arr.filter((e) => e.id === id);

  if (res) {
    return [200, res];
  }

  return [404];
}

function _data(type: LookupType): Array<LookupEntity> {
  if (type === 'group') {
    return groups;
  } else if (type === 'tag') {
    return tags;
  } else if (type === 'user') {
    return users;
  }
  return [];
}
