import { BaseRepository, PagerListResponse, BasePaginatedParams, HttpService } from '@ravnur/http';
import pathes from '@ravnur/shared/config/pathes';
import {
  Media,
  MyMedia,
  TrimInfo,
  ClipInfo,
  Media$Privacies,
  Media$Types,
  Timedata,
  TimedataSegment,
  TimedataThumbnail,
} from '@ravnur/shared/types/Media';
import axios from 'axios';
import { decodeVtt } from '@ravnur/helpers/vtt';

export type MyMediaRequestParams = {
  types: Media$Types[] | Media$Types;
  title: string;
  hasCC: boolean;
  privacy: Media$Privacies;
  isExpired: boolean;
  isReviewed: boolean;
  application: string;
  isFacets: string;
} & BasePaginatedParams;

type LR = PagerListResponse<Media>;

export enum TimedataAbbreviation {
  CAPTION = 'cc',
  CUE_POINT = 'cp',
}

export default class MyMediaRepository extends BaseRepository<MyMedia, MyMediaRequestParams, LR> {
  constructor() {
    super(pathes.MEDIA);
  }

  public trimVideo(mediaId: string, info: TrimInfo) {
    return this.action('job/trimming', info, mediaId);
  }

  public makeClip(mediaId: string, info: ClipInfo) {
    return this.action('job/clipping', info, mediaId);
  }

  public process(mediaId: string) {
    return this.action(`${mediaId}/process`);
  }

  public async undoUploading(mediaId: string, options: Dictionary<unknown> = {}) {
    return this.undo('upload', options, mediaId);
  }

  public async cancelJobs(mediaId: string) {
    return this.undo('job', {}, mediaId);
  }

  public async getTimedata(mediaId: string): Promise<Timedata[]> {
    return HttpService.get(`${this._resource}/${mediaId}/cc`);
  }

  public async getTimedataByLanguage(
    mediaId: string,
    language: string,
    timedataAbr: TimedataAbbreviation
  ): Promise<Timedata> {
    return HttpService.get(`${this._resource}/${mediaId}/${timedataAbr}/${language}`);
  }

  public updateTimedata(
    mediaId: string,
    language: string,
    segments: TimedataSegment[],
    timedataAbr: TimedataAbbreviation
  ) {
    return HttpService.put(`${this._resource}/${mediaId}/${timedataAbr}/${language}`, segments);
  }

  public toggleTimedataState(
    mediaId: string,
    language: string,
    state: string,
    timedataAbr: TimedataAbbreviation
  ) {
    return HttpService.post(`${this._resource}/${mediaId}/${timedataAbr}/${language}/${state}`, {});
  }

  public createNewCuePoint(mediaId: string, language: string, data: Partial<TimedataSegment>[]) {
    return HttpService.post(`${this._resource}/${mediaId}/cp/${language}`, data);
  }

  public deleteTimedata(mediaId: string, language: string, timedataAbr: TimedataAbbreviation) {
    return HttpService.delete(`${this._resource}/${mediaId}/${timedataAbr}/${language}`);
  }

  public generateCC(mediaId: string, language: string) {
    return HttpService.post(`${this._resource}/${mediaId}/index/${language}`, {});
  }

  public getTranscriptByLanguage(mediaId: string, language: string): Promise<string> {
    return HttpService.get(`${this._resource}/${mediaId}/transcript/${language}`);
  }

  public async generateAiDescription(mediaId: string): Promise<{ description: string }> {
    const response: Operation<{ description: string }> = await HttpService.get(
      `${this._resource}/${mediaId}/aidescription`
    );

    if (response.state === 2) {
      throw new Error(response.errorReason[0]);
    } else {
      return response.data;
    }
  }

  public async generateAiChapters(mediaId: string): Promise<Timedata> {
    const response: Operation<Timedata> = await HttpService.get(
      `${this._resource}/${mediaId}/aichapters`
    );
    if (response.state === 2) {
      throw new Error(response.errorReason[0]);
    } else {
      return response.data;
    }
  }

  public async getThumbnailsFromPreview(previewUrl: string): Promise<TimedataThumbnail[]> {
    const vttResponse = await axios.get(previewUrl);
    return decodeVtt(vttResponse.data) as TimedataThumbnail[];
  }
}
