import Login from '@ravnur/auth/pages/login-page/login-page';
import { AuthService } from '@ravnur/auth/types/AuthService';
import { COMPONENT_CTX_PROVIDER_KEY } from '@ravnur/core/constants';
import { Prop, Provide } from '@ravnur/decorators';
import { wait } from '@ravnur/http';
import $t from '@ravnur/l10n/$t';
import { PortalApplicationPage as PortalLayout } from '@ravnur/layout';
import acls from '@ravnur/shared/helpers/acls';
import { ApplicationContext } from '@ravnur/shared/types/ApplicationContext';
import { Options, Vue } from 'vue-class-component';

import Sidebar from '@/components/my-account/sidebar/sidebar';
import { DEFAULT_PAGE_SIZE } from '@/config/constants';
import AuthRepository from '@/repositories/auth-repository';
import { Auth$Response } from '@/types/Auth';
import { ApplicationNames, ApplicationTypes } from '@ravnur/shared/types/Application';
import usePrompt from '@ravnur/hooks/usePrompt';

import './my-account-portal.scss';

const CN = 'my-account-portal';
const BASE_API_PREFIX = process.env.VUE_APP_API_URL || '';
const authService: AuthService<Auth$Response> = {
  repository: new AuthRepository(),
  operationWaiter,
  providerPathGenerator,
};

@Options({
  components: {
    Sidebar,
  },
})
export default class MyAccountPortal extends Vue {
  @Provide('PAGINATED_REPOSITORY_PAGE_SIZE')
  protected pageSize: number = DEFAULT_PAGE_SIZE;

  @Provide(COMPONENT_CTX_PROVIDER_KEY) protected context: ComponentContext = {
    disabled: false,
  };

  @Provide('application-context') protected applicationContext: ApplicationContext = {
    isAudios: false,
    isDocuments: false,
    isEmailDomainManagement: false,
    isMediaLikes: false,
    isMediaRating: false,
    isSmComments: false,
    isVideos: false,
    isFaq: false,
    isContactInfo: false,
    isPrivacyPolicy: false,
    isTermSandConditions: false,
  };

  @Provide('application-name')
  protected applicationName = ApplicationNames.MY_ACCOUNT;

  @Prop({ type: Function, default: () => ({}) }) registerGA: (gaId: string) => void;
  @Prop({ type: Function, default: () => ({}) }) registerAppInsights: (id: string) => void;
  @Prop({ type: Function, default: () => ({}) }) registerGTM: (gtmId: string) => void;

  private hasAccessToAdmin = false;
  private uploadAvailable = false;
  private canUpload = false;
  private sidebarOpen = false;

  private get isAuth() {
    return this.store.user.isAuth;
  }

  private get slots() {
    return {
      header: this.renderHeader,
    };
  }

  private get sites() {
    return this.store.application.sites.map((s) => s.userApplication);
  }

  created() {
    usePrompt(() => this.store.upload.isProcessing);
  }

  render() {
    if (this.isAuth) {
      return (
        <PortalLayout
          currentSite={this.store.application.getCurrentSite}
          footerSettings={this.store.application.footerSettings}
          isAdmin={this.hasAccessToAdmin}
          logout={this.logout}
          portalSettings={this.applicationContext}
          sidebarOpen={this.sidebarOpen}
          sites={this.sites}
          toAdminPanel={this.toAdminPanel}
          toMyAccount={this.toMyAccount}
          user={this.store.user.getCurrentUser as User$Details}
          v-slots={this.slots}
        />
      );
    }
    return <Login service={authService} onAuth={this.handleAuthEvent} onError={this.handleError} />;
  }

  private renderHeader() {
    return (
      <>
        <h1 class={`${CN}__header`}>
          <router-link to="/">{$t('header', 'user-menu__my-account')}</router-link>
        </h1>
        <div class={`${CN}__upload-btn`}>
          <r-button
            color="primary"
            icon="portal-upload"
            mode="secondary"
            onclick={this.toUpload}
            title={$t('header', 'menu__upload')}
            v-show={this.canUpload && this.uploadAvailable}
          />
        </div>
        <Sidebar onToggle={this.handleSidebarToggle} />
      </>
    );
  }

  private handleSidebarToggle(state: boolean) {
    this.sidebarOpen = state;
  }

  handleError(e: Record<string, any>) {
    if (e.status !== 401) {
      this.$processException(e);
    }
  }

  private handleAuthEvent(resp: Auth$Response) {
    this.store.handleAuthResponse(resp);

    const settings = acls.getAggregatedPortalSettings();
    if (settings) {
      this.applicationContext.isAudios = settings.isAudios;
      this.applicationContext.isDocuments = settings.isDocuments;
      this.applicationContext.isEmailDomainManagement = settings.isEmailDomainManagement;
      this.applicationContext.isMediaLikes = settings.isMediaLikes;
      this.applicationContext.isMediaRating = settings.isMediaRating;
      this.applicationContext.isSmComments = settings.isSmComments;
      this.applicationContext.isVideos = settings.isVideos;
      this.applicationContext.isContactInfo = settings.isContactInfo;
      this.applicationContext.isFaq = settings.isFaq;
      this.applicationContext.isPrivacyPolicy = settings.isPrivacyPolicy;
      this.applicationContext.isTermSandConditions = settings.isTermSandConditions;

      this.uploadAvailable = settings.isUploads;
    }

    const perms = acls.getUserPermissions();

    if (perms) {
      this.hasAccessToAdmin = perms.hasAccessToAdmin;

      this.canUpload = perms.canUploadContent;
    }

    const configurations = acls.getApplicationConfigurations();

    if (configurations?.analytics.gaId) {
      this.registerGA(configurations.analytics.gaId);
    }

    if (configurations?.analytics.gtmID) {
      this.registerGTM(configurations.analytics.gtmID);
    }

    // Temporary hardocde
    // if (configurations?.analytics.appInsightId) {
    //   this.registerAppInsights(configurations.analytics.appInsightId);
    // }
    this.registerAppInsights(
      'InstrumentationKey=317ff99b-5d2e-4367-8de5-161188a18530;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/'
    );

    document.title = this.store.application.getCurrentSite?.name || 'My Account';

    this.store.jobs.pulling();
    this.store.languages.load();
  }

  private logout() {
    this.store.logout();
  }

  private toMyAccount() {
    this.$router.push({ name: 'ProfileSettings' });
  }

  private toAdminPanel() {
    const adminApp = this.store.application.getApplicationSites.find(
      (s) => s.applicationType === ApplicationTypes.BASIC
    );

    const adminUrl = adminApp?.adminUrl;

    if (adminUrl) {
      window.location.href = adminUrl;
    }
  }

  private toUpload() {
    this.$router.push({ name: 'Upload' });
  }
}

function providerPathGenerator(loginEndpointPath: string): string {
  const url = encodeURIComponent(window.location.href);

  return `${BASE_API_PREFIX}/${loginEndpointPath}?url=${url}`;
}

async function operationWaiter(operationId: string): Promise<void> {
  await wait({
    entityId: null,
    errorReason: [],
    operationId,
    data: {},
    state: 0,
  });
}
