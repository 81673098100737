import TextField from '@ravnur/core/ui-kit/text-field/text-field';
import $t from '@ravnur/l10n/$t';
import { Folder } from '@ravnur/shared/types/Folder';
import { useVuelidate } from '@vuelidate/core';
import { Vue, Options, setup } from 'vue-class-component';

import { required } from '@/validation.rules';

import './favorites-folder-editor-modal.scss';

const CN = 'favorites-folder-editor-modal';

class Props {
  folder: Nullable<Folder>;
  close: () => void;
}

@Options({
  validations: {
    name: { required, $autoDirty: true },
  },
})
export default class FavoritesFolderEditorModal extends Vue.with(Props) {
  declare $refs: {
    'folder-name': TextField;
    modal: Vue & { handleConfirmEvent: () => void };
  };
  private v$ = setup(() => useVuelidate());

  private name = '';
  private saving = false;

  private get isEdit() {
    return !!this.folder;
  }

  private get modalOptions() {
    return {
      mode: 'fixed',
      confirmText: $t('common', this.isEdit ? 'action__save' : 'action__create'),
      showFooter: true,
      processing: this.saving,
      canConfirmChecker: this.canConfirmChecker,
      disabledConfirm: this.v$.name.$errors.length || !this.name,
      header: $t('common', this.isEdit ? 'folder__edit_header' : 'folder__create_header'),
    };
  }

  created() {
    this.name = this.folder?.name ?? '';
  }

  mounted() {
    this.$nextTick(() => {
      this.$refs['folder-name'].focus();
    });
  }

  render() {
    return (
      <modal ref="modal" class={CN} options={this.modalOptions} {...this.$attrs} close={this.close}>
        <text-field
          ref="folder-name"
          class={`${CN}__field`}
          errors={this.v$.name.$errors}
          label={$t('common', 'folder__name')}
          maxLength={30}
          placeholder={$t('common', 'folder__create_name_placeholder')}
          vModel={[this.name, 'value']}
        />
      </modal>
    );
  }

  private async save() {
    if (!(await this.v$.$validate?.())) {
      return;
    }

    this.saving = true;
    try {
      const folder = { isDefault: false, ...this.folder, name: this.name };
      await this.store.favoritesFolders.save(folder);
      this.close();
    } catch (e) {
      this.$processException(e);
    }
    this.saving = false;
  }

  private canConfirmChecker() {
    this.save();
    return false;
  }
}
