<template>
  <Forbidden />
</template>

<script>
import Forbidden from '@ravnur/shared/components/forbidden.vue';

export default {
   name: 'ForbiddenComponent',
   components: {
     Forbidden,
   }
};
</script>

<style lang="scss">
   .forbidden {
      @media #{$laptop} {
         padding-left: 64px;
      }
   }
</style>