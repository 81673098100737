import groupBy from '@ravnur/nanoutils/groupBy';
import { Folder, Folder$Node } from '@ravnur/shared/types/Folder';

const groupByParentId = groupBy('parentId');

export default function toNodeFormat(folders: Folder[]): Folder$Node[] {
  const cache = groupByParentId(folders as any) as {
    null: Folder[];
    [key: string]: Folder[];
  };

  return convertMany(cache.null || cache.undefined || []);

  function convert(folder: Folder): Nullable<Folder$Node> {
    if (!folder.id) {
      return null;
    }

    const node: Folder$Node = {
      id: folder.id,
      name: folder.name,
      description: folder.description || '',
      isDefault: folder.isDefault,
      expanded: false,
    };

    const children = cache[folder.id] || [];
    node.children = convertMany(children);

    return node;
  }

  function convertMany(arr: Folder[]): Folder$Node[] {
    return arr.reduce((memo: Folder$Node[], folder) => {
      const node = convert(folder);
      if (node) {
        memo.push(node);
      }
      return memo;
    }, []);
  }
}
