// tslint:disable no-var-requires

import { keyById } from '@ravnur/nanoutils/keyBy';
import last from '@ravnur/nanoutils/last';
import { User, User$Details } from '@ravnur/shared/types/User';
import { AxiosRequestConfig } from 'axios';
import MockAdapter from 'axios-mock-adapter/types';
import { faker } from '@faker-js/faker';

import { startOperation } from './operation';
import { _generateMockList, _generateUser } from './helper-fakers';

const count = +faker.random.numeric(2);
const data = _generateMockList(count, _generateUser);

const cache = keyById(data);

interface Users$Params {
  Offset?: number;
  Count?: number;
  Q?: string;
}

export default function mocker(mock: MockAdapter) {
  mock.onGet(/\/api\/v1.0\/followed\/user/).reply(_loadByPages);
  mock.onGet('/api/v1.0/user').reply(_load);
  mock.onPut(/\/api\/v1\.0\/user\/\d+/).reply(_change);
  mock.onPost(/\/api\/v1\.0\/user\/\d+\/password/).reply(_doSomething);
  mock.onPost(/\/api\/v1\.0\/user\/\d+\/follow/).reply(_doSomething);
  mock.onDelete(/\/api\/v1\.0\/user\/\d+\/follow/).reply(_doSomething);
  mock.onGet(/\/api\/v1\.0\/user\/\d+/).reply(_find);
}

function _load(config: AxiosRequestConfig) {
  const params: Users$Params = config.params;

  const q = params.Q;

  let res: User[] = data;
  if (q) {
    res = res.filter((c) => c.displayName.indexOf(q) > -1);
  }

  const count = params.Count;

  if (count) {
    const offset = params.Offset || 0;
    res = res.slice(offset, offset + count);
  }

  return [200, res];
}

function _loadByPages(config: AxiosRequestConfig) {
  const params: Users$Params = config.params;

  const q = params.Q;

  let res: User[] = data;
  if (q) {
    res = res.filter((c) => c.displayName.indexOf(q) > -1);
  }

  const count = params.Count || 0;

  const total = res.length;
  const offset = params.Offset || 0;
  const items = res.slice(offset, offset + count);

  return [200, { items, total }];
}

function _doSomething() {
  const operation = startOperation('id');
  return [200, operation];
}

function _find(config: AxiosRequestConfig) {
  const url = config.url || '';
  const id = last(url.split('/')) || '';
  const user = cache[id];
  return user ? [200, { ...user }] : [404];
}

function _change(config: AxiosRequestConfig) {
  const user = JSON.parse(config.data) as User$Details;
  data.forEach((u) => {
    if (u.id === user.id) {
      u.displayName = user.displayName;
      u.userpic = user.userpic;
      u.email = user.email;
    }
  });
  return _doSomething();
}
