import { Vue, Options, prop } from 'vue-class-component';

import './paper.scss';

const CN = 'paper';

class Props {
  force = prop({ default: true });
  disabled = prop({ default: false });
}

@Options({
  inheritAttrs: false,
})
export default class Paper extends Vue.with(Props) {
  render() {
    const cn = {
      [CN]: true,
      [`${CN}--force`]: this.force,
      [`${CN}--disabled`]: this.disabled,
    };
    return (
      <div class={cn} {...this.$attrs}>
        {this.$slots.default?.()}
      </div>
    );
  }
}
