import { ThumbnailableEntity } from './Entity';
import { Media } from './Media';
import { User } from './User';

export enum Group$Privacy {
  PUBLIC = 0,
  RESTRICTED = 1,
  PRIVATE = 2,
}

export enum Group$MediaPosting {
  IMMEDIATELY = 0,
  PRIVATE = 2,
}

export enum Group$JoinState {
  NON_MEMBER = 0,
  MEMBER = 1,
  REQUEST = 2,
}

export interface Group$PinnedMedia {
  order: number;
  media: Media;
}

export type Group = {
  applicationId: Nullable<string>;
  name: string;
  description: string;
  joinState: Group$JoinState;
  privacy: Group$Privacy;
  mediaPosting: Group$MediaPosting;
  mediaCount: number;
  membersCount: number;
  commentsCount: number;
  founder: User;
  createdDate: string;

  bgColor: string;
  hideMembers: boolean;
  disableDiscussions: boolean;
  enablePinned: boolean;
  pinnedMedia: Group$PinnedMedia[];
  isAdmin: boolean;
  membersCountForApproval: number;
  mediaCountForApproval: number;
} & ThumbnailableEntity;

export enum Group$MemberRole {
  OWNER = 'Owner',
  ADMIN = 'Admin',
  MEMBER = 'Member',
  PENDING = 'Pending',
  INVITED = 'Invited',
}

export type Group$Member = {
  userName: string;
  displayName: string;
  role: Group$MemberRole;
  createdDate: string;
} & Entity;

export type Group$AddToLookup = {
  name: string;
  privacy: Group$Privacy;
  containsCurrentMedia?: boolean;
} & Entity;
