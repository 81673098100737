import { PortalConfigurations } from '../../types/Configurations';
import { PortalSettings } from '../../types/PortalSettings';
import { UserPermissions } from '../../types/UserPermissions';

import { AggregatePortalSettings } from './aggregated';
import { UserPermissionsHelper } from './permissions';

type InputData = {
  applicationId: string;
  portalFeatures: PortalSettings;
  userPermissions: UserPermissions;
  portalConfiguration: PortalConfigurations;
  portalUrl: string;
};

type AclsData = {
  userPermissions: UserPermissionsHelper;
  portalFeatures: PortalSettings;
  portalConfiguration: PortalConfigurations;
  applicationId: string;
};

class Service {
  private applicationId: Nullable<string> = null;
  private data: Record<string, AclsData> = {};
  private aggregated: Nullable<AggregatePortalSettings> = null;

  init(sites: InputData[]) {
    const [path] = window.location.href.split('#');
    const id = sites.find((s) => path.includes(s.portalUrl))?.applicationId ?? null;
    this.applicationId = id;

    if (process.env.NODE_ENV === 'development') {
      this.applicationId = process.env.VUE_APP_APPLICATION_ID || '';
    }

    this.data = sites.reduce((memo: Record<string, AclsData>, sap) => {
      const data: AclsData = {
        applicationId: sap.applicationId,
        portalFeatures: Object.freeze({ ...sap.portalFeatures }),
        portalConfiguration: Object.freeze({ ...sap.portalConfiguration }),
        userPermissions: new UserPermissionsHelper([sap.userPermissions]),
      };
      return { ...memo, [data.applicationId]: data };
    }, {});

    this.aggregated = new AggregatePortalSettings(sites.map((s) => s.portalFeatures));
  }

  private getEntityForApplication<K extends keyof AclsData>(
    applicationId: Nullable<string>,
    entityName: K
  ): Nullable<AclsData[K]> {
    if (!applicationId) {
      return null;
    }

    return this.data[applicationId]?.[entityName] ?? null;
  }

  getUserPermissions(applicationId: Nullable<string> = this.applicationId) {
    return this.getEntityForApplication(applicationId, 'userPermissions');
  }

  getApplicationSettings(applicationId: Nullable<string> = this.applicationId) {
    return this.getEntityForApplication(applicationId, 'portalFeatures');
  }

  getApplicationConfigurations(applicationId: Nullable<string> = this.applicationId) {
    return this.getEntityForApplication(applicationId, 'portalConfiguration');
  }

  getAggregatedPortalSettings() {
    return this.aggregated;
  }
}

export default new Service();
