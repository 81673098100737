import { ref } from 'vue';

import { useEventListener } from './useEventListener';

type WindowSizeOptions = {
  initialWidth?: number;
  initialHeight?: number;
};

export function useWindowSize({
  initialWidth = Infinity,
  initialHeight = Infinity,
}: WindowSizeOptions = {}) {
  if (!window) {
    return {
      width: ref(initialWidth),
      height: ref(initialHeight),
    };
  }
  const width = ref(window.innerWidth);
  const height = ref(window.innerHeight);

  useEventListener(
    'resize',
    () => {
      width.value = window.innerWidth;
      height.value = window.innerHeight;
    },
    { passive: true }
  );

  return { width, height };
}
