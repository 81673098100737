import { defineComponent } from 'vue';

import './placeholder.scss';

const CN = 'placeholder';

export default defineComponent({
  setup(props, ctx) {
    return () => <div class={CN}>{ctx.slots.default?.()}</div>;
  },
});
