import { cleanLocation } from '@ravnur/auth/helpers/location-cleaner';
import { refreshCookieName } from '@ravnur/auth/helpers/refresh-cookie-name';
import vhCheck from 'vh-check';
import { createApp } from 'vue';
import { createRouter, createWebHashHistory } from 'vue-router';
import VueGtag from 'vue-gtag';

import { BasePortalPlugin } from './base.portal.plugin';
import PortalApplication from './pages/my-account-portal';
import { ROUTES } from './routes';
import store from './store';
import registryKit from './ui-kit/index';

import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import './axios-interceptors';
import './icons';
import 'normalize.css';

// FIxes Buffer issue in RavnurC2PA module
import { Buffer } from 'buffer';

global.Buffer = Buffer;

if (process.env.VUE_APP_MOCK_API === 'yes') {
  require('./mocks');
}

vhCheck();
const { cn } = cleanLocation('cn');
if (typeof cn === 'string') {
  refreshCookieName(cn);
}

const router = createRouter({
  history: createWebHashHistory('/'),
  routes: ROUTES,
});

const app = createApp(PortalApplication, { registerGA, registerAppInsights, registerGTM });

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  logErrors: true,
  environment: process.env.VUE_APP_DEPLOY_ENV,
  enabled: process.env.VUE_APP_DEPLOY_ENV === 'uat' || process.env.VUE_APP_DEPLOY_ENV === 'prod',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['hubtvuatmyaccount.ravnur.net', 'hubtv-myaccount.corp.ebay.com'],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

// Automatically unwrap computed refs in provide.
// Required to make provide/inject reactive
app.config.unwrapInjectedRef = true;

app.use(store);
app.use(BasePortalPlugin);
app.use(router);
registryKit(app);
app.mount('#app');

function registerGA(gaId: string) {
  app.use(
    VueGtag,
    {
      config: { id: gaId },
    },
    router
  );
}

function registerAppInsights(connectionString: string) {
  const appInsights = new ApplicationInsights({
    config: { connectionString },
  });
  const telemetryInitializer = (envelope: any) => {
    envelope.tags['ai.cloud.role'] = window.location.host;
    envelope.tags['ai.cloud.roleInstance'] = window.location.host;
  };
  appInsights.addTelemetryInitializer(telemetryInitializer);
  appInsights.loadAppInsights();
  router.beforeResolve((to, from, next) => {
    appInsights.trackPageView({ name: to.path });
    next();
  });
}

function registerGTM(gtmId: string) {
  const script = `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer', '${gtmId}');
`;

  const scriptTag = document.createElement('script');
  scriptTag.innerHTML = script;
  document.head.appendChild(scriptTag);

  const iframe = document.createElement('iframe');
  iframe.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=${gtmId}`);
  iframe.setAttribute('height', '0');
  iframe.setAttribute('width', '0');
  iframe.setAttribute('style', 'display:none;visibility:hidden');

  const noscriptTag = document.createElement('noscript');
  noscriptTag.appendChild(iframe); // Use innerHTML to set HTML content
  document.body.prepend(noscriptTag);
}
