import { refreshCookieName } from '@ravnur/auth/helpers/refresh-cookie-name';
import { removeAuthToken } from '@ravnur/auth/helpers/remove-auth-token';
import * as l10n from '@ravnur/l10n/service';
import pathes from '@ravnur/shared/config/pathes';
import acls from '@ravnur/shared/helpers/acls';
import { Folder } from '@ravnur/shared/types/Folder';
import { Language } from '@ravnur/shared/types/Language';
import { PredefinedMediaProperties } from '@ravnur/shared/types/Media';
import { Module, Action, State, Mutation } from 'vuex-simple';

import AuthRepository from '@/repositories/auth-repository';
import ThesaurusRepository, {
  LinearThesaurusRepository,
} from '@/repositories/thesaurus-repository';
import { ThesaurusModule } from '@/store/modules/generics/thesaurus';
import { Auth$Response } from '@/types/Auth';

import { ApplicationModule } from './modules/application';
import { JobsModule } from './modules/jobs';
import { UploadModule } from './modules/upload';
import { UserModule } from './modules/user';
import $t from '@ravnur/l10n/$t';
import ModalService from '@ravnur/modal';
import enUS from '@/translations/en-US.json';

export class RooStore {
  @Module()
  public user = new UserModule();
  @Module()
  public application = new ApplicationModule();
  @Module()
  public upload = new UploadModule();
  @Module()
  public jobs = new JobsModule(this);

  @Module()
  public favoritesFolders = new ThesaurusModule(
    new ThesaurusRepository<Folder>('/favorites/folder')
  );

  @Module()
  public languages = new ThesaurusModule(new LinearThesaurusRepository<Language>(pathes.LANGUAGES));

  @State()
  public predefinedMediaProperties: PredefinedMediaProperties = {};

  @State()
  public isLogouting = false;

  @Action()
  public handleAuthResponse(resp: Auth$Response) {
    this.user.changeCurrentUser(resp.user);
    this.user.changeUserCounters(resp.userCounters);
    this.application.changeDefaultMediaProperties(resp.predefinedMediaProperties);
    this.application.changeSites(resp.applications);
    this.application.changeLanguages(resp.supportedLocales);
    this.application.setPublicDomain(resp.publicDomain);

    acls.init(
      resp.applications.map((data) => ({
        applicationId: data.applicationId,
        portalFeatures: data.portalFeatures,
        portalUrl: data.userApplication.portalUrl,
        portalConfiguration: data.portalConfiguration,
        userPermissions: data.userPermissions,
      }))
    );

    const portalConfigurations = acls.getApplicationConfigurations();

    if (portalConfigurations) {
      this.upload.changeAudioFormats(portalConfigurations);
      this.upload.changeVideoFormats(portalConfigurations);
      this.application.setFooterSettings(portalConfigurations.settings);
    }

    l10n.init(generateLocaleInitInfo(resp), { [l10n.AVAILABLE_LOCALES.ENGLISH]: enUS });
    refreshCookieName(resp.cookieName);
  }

  @Action()
  public async logout() {
    if (this.upload.isProcessing) {
      const msg = $t('common', 'upload-interrupt');
      try {
        await ModalService.confirm(msg, {
          header: $t('common', 'uploading-in-progress'),
          confirmText: $t('common', 'upload-interrupt-confirm'),
          cancelText: $t('common', 'upload-interrupt-decline'),
        });
        this.upload.logout();
      } catch (e) {
        return;
      }
    }

    if (!this.user.isAuth) {
      location.reload();
      return;
    }
    this.startLogout();
    removeAuthToken();
    const repository = new AuthRepository();
    try {
      await repository.logout();
    } catch (e) {
      // noop
    } finally {
      this.addLogoutCode();
    }
  }

  @Mutation()
  public startLogout() {
    this.isLogouting = true;
  }

  @Mutation()
  public addLogoutCode() {
    this.isLogouting = true;
    const delim = location.href.indexOf('?') >= 0 ? '&' : '?';
    location.href = location.href + delim + 'successCode=success_logout';
    location.reload();
  }
}

function generateLocaleInitInfo(resp: Auth$Response): Translation$Locale {
  return {
    supportedLocales: resp.supportedLocales,
    translationHash: resp.translationHash,
    userLang: resp.user.languageId,
  };
}
