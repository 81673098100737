import { Vue, Options, prop } from 'vue-class-component';

import { FileUploadInfo } from '../../helpers/fileuploader.helpers';
import FileSelector from '../file-selector/hidden-file-selector';

class Props {
  path: string;
  accept = prop({ default: '' });

  onChange?: (file: File) => void;
}

@Options({
  emits: ['change'],
})
export default class FileUploader extends Vue.with(Props) {
  declare $refs: {
    field: FileSelector;
  };

  public browse() {
    this.$refs.field.browse();
  }

  public isSelected(): boolean {
    return this.$refs.field.isSelected();
  }

  public createFileUploadInfo(): Nullable<FileUploadInfo> {
    const el = this.$refs.field.$refs.field;
    if (el instanceof HTMLInputElement && el.value) {
      return new FileUploadInfo(this.path, el);
    }
    return null;
  }

  public clear() {
    this.$refs.field.clear();
  }

  private handleChangeEvent(file: Nullable<File>) {
    this.$emit('change', file);
  }

  render() {
    return <FileSelector ref="field" accept={this.accept} onFileChange={this.handleChangeEvent} />;
  }
}
