import registryCoreKit from '@ravnur/core/ui-kit';
import Accordion from '@ravnur/shared/components/accordion/accordion';
import ActionButton from '@ravnur/shared/components/action-button/action-button';
import Alert from '@ravnur/shared/components/alert/alert';
import Carousel from '@ravnur/shared/components/carousel/carousel.vue';
import Ellipsis from '@ravnur/shared/components/ellipsis/ellipsis';
import GridCard from '@ravnur/shared/components/grid-card/grid-card';
import Info from '@ravnur/shared/components/info/info';
import ListCard from '@ravnur/shared/components/list-card/list-card';
import Lookup from '@ravnur/shared/components/lookup/lookup';
import Messages from '@ravnur/shared/components/messages/messages';
import NamedBlock from '@ravnur/shared/components/named-block/named-block';
import Paper from '@ravnur/shared/components/paper/paper';
import Placeholder from '@ravnur/shared/components/placeholder/placeholder';
import Rating from '@ravnur/shared/components/rating/rating.vue';
import SearchInput from '@ravnur/shared/components/search-input/search-input';
import StatsButton from '@ravnur/shared/components/stats-btn/stats-btn';
import Stats from '@ravnur/shared/components/stats/stats';
import Tag from '@ravnur/shared/components/tags/tag';
import Tags from '@ravnur/shared/components/tags/tags';
import Thumbnail from '@ravnur/shared/components/thumbnail/thumbnail';
import { App } from 'vue';

export default function registry(app: App) {
  registryCoreKit(app);
  app.component('accordion', Accordion);
  app.component('action-button', ActionButton);
  app.component('portal-alert', Alert);
  app.component('carousel', Carousel);
  app.component('ellipsis', Ellipsis);
  app.component('grid-card', GridCard);
  app.component('list-card', ListCard);
  app.component('lookup', Lookup);
  app.component('messages', Messages);
  app.component('paper', Paper);
  app.component('placeholder', Placeholder);
  app.component('info', Info);
  app.component('rating', Rating);
  app.component('search-input', SearchInput);
  app.component('stats', Stats);
  app.component('stats-btn', StatsButton);
  app.component('tag', Tag);
  app.component('tags', Tags);
  app.component('thumbnail', Thumbnail);
  app.component('named-block', NamedBlock);
}
