import shuffle from '@ravnur/nanoutils/shuffle';
import { Group } from '@ravnur/shared/types/Group';
import { Media$Details, Media$Types } from '@ravnur/shared/types/Media';
import { AxiosRequestConfig } from 'axios';
import MockAdapter from 'axios-mock-adapter/types';
import { faker } from '@faker-js/faker';
import * as casual from './casual';
import { startOperation } from './operation';
import {
  _generateGroup,
  _generateMedia,
  _generateMockList,
  _generateTimedata,
} from './helper-fakers';

const count = +faker.random.numeric(2);
const data = _generateMockList<Media$Details>(count, _generateMedia as any);
const groups = _generateMockList<Group>(10, _generateGroup);

export default function mocker(mock: MockAdapter) {
  mock.onGet(/\/api\/v1\.0\/media\/\d+\/items/).reply(_playlistItems);
  mock.onGet(/\/api\/v1\.0\/media\/\d+\/related/).reply(_load);
  mock.onGet(/\/api\/v1\.0\/media\/\d+\/cc/).reply(_getTimedata);
  mock.onGet(/\/api\/v1\.0\/media\/\d+/).reply(_get);
  mock.onGet(/\/api\/v1\.0\/media/).reply(_load);
  mock.onGet(/\/api\/v1\.0\/media\/\d+\/group/).reply(_loadGroups);
  mock.onGet(/\/api\/v1\.0\/media\/\d+\/playlist/).reply(_loadPlaylist);
  mock.onPost(/\/api\/v1\.0\/media\/\d+\/playlist\/\d+/).reply(_addToPlaylist);
  mock.onDelete(/\/api\/v1\.0\/media\/\d+\/playlist\/\d+/).reply(_removeFromPlaylist);
  mock.onGet(/\/api\/v1\.0\/viewing-history/).reply(_load);
  mock.onGet(/\/favorites$/).reply(_load);
}

function _getTimedata() {
  const timedata = _generateMockList(4, _generateTimedata);
  return [200, timedata];
}

function _get() {
  const media = faker.helpers.arrayElement(data);
  return [200, media];
}

function _load(config: AxiosRequestConfig) {
  let type = config.params.Types;
  let items = data;

  if (type === Media$Types.PLAYLIST) {
    type = [Media$Types.AUDIO_PLAYLIST, Media$Types.VIDEO_PLAYLIST];
  }

  if (type) {
    items = data.filter((m) => m.type === type || type.includes(m.type));
  }

  return [200, { tota: items.length, items, facets: [] }];
}

function _loadGroups() {
  return [200, { items: groups }];
}

function _loadPlaylist() {
  let playlist = data.filter(
    (m) => m.type === Media$Types.VIDEO_PLAYLIST || m.type === Media$Types.AUDIO_PLAYLIST
  );
  playlist = shuffle(playlist);
  return [200, playlist.slice(0, casual.integer(4))];
}

function _playlistItems() {
  let playlist = data.filter((m) => m.type === Media$Types.AUDIO || m.type === Media$Types.VIDEO);
  playlist = shuffle(playlist).map((media: Media$Details, order: number) => ({ ...media, order }));
  return [200, playlist]; // .slice(0, casual.integer(6))
}

function _addToPlaylist() {
  const operation = startOperation(`${Date.now()}`);
  return [200, operation];
}

function _removeFromPlaylist() {
  const operation = startOperation(`${Date.now()}`);
  return [200, operation];
}
