import { LiveEvent, LiveEvent$Status } from '../types/Live';

const STATUSES = [
  LiveEvent$Status.EXPIRED,
  LiveEvent$Status.ON_AIR,
  LiveEvent$Status.PREVIEW,
  LiveEvent$Status.SCHEDULED,
  LiveEvent$Status.FINISHED,
];

export function isLiveEventEntity(e: Entity): e is LiveEvent {
  const status = (e as any).status;
  return (e as any).channels && status ? STATUSES.some((t) => t === status) : false;
}
