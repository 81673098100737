import { Watch, Prop } from '@ravnur/decorators';
import $t from '@ravnur/l10n/$t';
import FileUploader from '@ravnur/shared/components/file-uploader/file-uploader';
import entities from '@ravnur/shared/config/pathes';
import uploaderHelper from '@ravnur/shared/helpers/fileuploader.helpers';
import generator from '@ravnur/shared/helpers/url.generator';
import { User } from '@ravnur/shared/types/User';
import { useVuelidate } from '@vuelidate/core';
import { Vue, Options, setup } from 'vue-class-component';
import humanFileSize from '@ravnur/helpers/human-file-size';

import UsersRepository from '@/repositories/users-repository';

import { required } from '@/validation.rules';
import { toFile } from '@ravnur/core/transformers/base64';
import { wait } from '@ravnur/http';
import { ThumbnailInfo } from '@ravnur/shared/types/Entity';
import { showErrorNotification } from '@ravnur/notifications/service';

import './sidebar-profile.scss';

const CN = 'sidebar-profile';

const repository = new UsersRepository();
const MAX_IMAGE_WIDTH = 104; // sidebar avatar element with
const MAX_IMAGE_SIZE = 100 * 500;

@Options({
  components: {
    FileUploader,
  },
  validations: {
    username: { required, $autoDirty: true },
  },
})
export default class SidebarProfile extends Vue {
  @Prop({ required: true, type: Boolean }) isCollapsed: boolean;

  declare $refs: {
    customThumbnail: FileUploader | undefined;
  };

  private v$ = setup(() => useVuelidate());

  private editable: Nullable<User> = null;
  public userNameFormVisible = false;
  private username = '';
  private saving = false;

  get user() {
    return this.store.user.current;
  }

  get path(): string {
    const { user } = this;
    if (!user) {
      return '';
    }
    return generator.upload(`${entities.USERS}/${user.id}/userpic`);
  }

  @Watch('isCollapsed')
  private handleCollapseChange() {
    if (this.saving) return;

    this.username = this.user?.displayName || '';
    this.userNameFormVisible = false;
  }

  @Watch('user', { immediate: true })
  public handleUserChanged() {
    const { user } = this;

    if (!user) return;

    this.editable = { ...user };
    this.username = user.displayName;
  }

  render() {
    if (!this.editable) return;

    return (
      <div class={CN}>
        {this.renderFileUploader()}
        <avatar class={`${CN}__avatar`} size="md" user={this.editable}>
          {this.renderAvatarActions()}
        </avatar>
        {this.renderUserName()}
        {this.renderUserNameForm()}
        <div
          class={`${CN}__user-email`}
          data-testid="sidebar-profile-email"
          title={this.editable?.email.length > 30 ? this.editable?.email : ''}
        >
          {this.editable?.email.substring(0, 30)}
          {this.editable?.email.length > 30 ? '...' : ''}
        </div>
      </div>
    );
  }

  private renderFileUploader() {
    return (
      <FileUploader
        ref="customThumbnail"
        accept="image/jpeg, image/png"
        class={`${CN}__thumbnail-uploader`}
        path={this.path}
        onChange={this.handleThumbnailChanged}
      />
    );
  }

  private renderUserNameForm() {
    if (!this.userNameFormVisible) return;

    return (
      <div class={`${CN}__user-name--form`}>
        <text-field
          errors={this.v$.username.$errors}
          maxLength={75}
          placeholder="Enter user name"
          vModel={[this.username, 'value']}
        />
        <r-button
          color="primary"
          disabled={!this.username}
          loading={this.saving}
          onclick={this.handleSubmitClick}
        >
          <l10n group="common" tkey="action__done" />
        </r-button>
      </div>
    );
  }

  private renderUserName() {
    if (this.userNameFormVisible) return;

    return (
      <div class={`${CN}__user-name`} data-testid="sidebar-profile-name">
        {this.editable?.displayName}
        <r-button
          class={`${CN}__user-name--edit`}
          color="black"
          data-testid="sidebar-profile-edit-name"
          icon="edit"
          mode="frameless"
          onclick={this.handleEditClick}
        />
      </div>
    );
  }

  private renderAvatarActions() {
    return this.editable?.userpic ? (
      <div class={`${CN}__edit-actions`}>
        <div class={`${CN}__change-avatar-btn`} onClick={this.openFileDialog}>
          {$t('common', 'profile__change-btn')}
        </div>
        <div class={`${CN}__remove-avatar-btn`} onClick={this.clearUserpic}>
          {$t('common', 'action__remove')}
        </div>
      </div>
    ) : (
      <div class={`${CN}__upload-action`} onClick={this.openFileDialog}>
        <icon type="camera" />
        <div class={`${CN}__upload-avatar-btn`}>{$t('common', 'profile__upload-btn')}</div>
      </div>
    );
  }

  private async handleThumbnailChanged(file: File | string) {
    const { editable } = this;
    if (editable && typeof file !== 'string' && 'FileReader' in window) {
      if (file.size > MAX_IMAGE_SIZE) {
        const size = humanFileSize(MAX_IMAGE_SIZE, true, 0);
        showErrorNotification($t('common', 'profile__max-size', { size }));

        return;
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        const img = document.createElement('img');
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          if (!ctx) {
            return;
          }

          let w = img.width;
          let h = img.height;

          if (w > h) {
            if (w > MAX_IMAGE_WIDTH) {
              h *= MAX_IMAGE_WIDTH / w;
              w = MAX_IMAGE_WIDTH;
            }
          } else {
            if (h > MAX_IMAGE_WIDTH) {
              w *= MAX_IMAGE_WIDTH / h;
              h = MAX_IMAGE_WIDTH;
            }
          }

          ctx.canvas.width = w;
          ctx.canvas.height = h;
          ctx.drawImage(img, 0, 0, w, h);
          const url = canvas.toDataURL(file.type);
          const userpic = url;
          this.editable = { ...editable, userpic };
          this.$nextTick(this.$forceUpdate);
        };
        img.src = e.target?.result as string;
      };

      reader.readAsDataURL(file);
    }

    await this.handleSubmitClick(); // Auto save after thumbnail change
  }

  private async uploadUserpicIfNeeded() {
    const fileuploader = this.$refs.customThumbnail;

    if (!fileuploader) return;

    const { user, editable } = this;
    const isSelected = fileuploader.isSelected();

    if (isSelected && this.editable?.userpic) {
      const file = await toFile(this.editable?.userpic, `${Date.now()}.jpeg`, 'image/jpeg');
      const fileExt = file.name.split('.').pop();

      const operation: Operation<ThumbnailInfo> = await uploaderHelper.uploadFile(this.path, file, {
        data: { fileExt },
      });

      await wait(operation);
    } else if (user && user.userpic && editable && !editable.userpic) {
      await repository.removeUserpic(user);
    }
  }

  private async handleSubmitClick() {
    const isValid = await this.v$.$validate?.();

    if (!isValid || !this.user) return;

    this.saving = true;

    try {
      await repository.save({ ...this.user, displayName: this.username });
      await this.uploadUserpicIfNeeded();
      const current = await repository.get(this.user.id);
      this.store.user.changeCurrentUser(current);
    } catch (e) {
      this.$processException(e);
    } finally {
      this.saving = false;
      this.userNameFormVisible = false;
    }
  }

  private handleEditClick() {
    this.userNameFormVisible = true;
  }

  private openFileDialog() {
    this.$refs.customThumbnail?.browse();
  }

  private async clearUserpic() {
    if (this.editable) {
      this.editable.userpic = null;
    }
    this.$refs.customThumbnail?.clear();
    await this.handleSubmitClick();
  }
}
