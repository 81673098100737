import { Vue, prop } from 'vue-class-component';

import HintIcon from '../hint-icon/hint-icon';

import './named-block.scss';

const CN = 'named-block';

type Level = 1 | 2;

class Props {
  title = prop({ default: '' });
  level = prop<Level>({ default: 1 });
  hint = prop({ default: '' });
}

// TODO use defineComponent instead of vue-class-component
export default class NamedBlock extends Vue.with(Props) {
  render() {
    return (
      <div class={CN}>
        <div class={[`${CN}__title`, `${CN}__title--l${this.level}`]} v-show={this.title}>
          {this.title}
          <HintIcon hint={this.hint} />
        </div>
        {this.$slots.default?.()}
      </div>
    );
  }
}
