import ellipsis from '@ravnur/core/filters/ellipsis';
import { Prop } from '@ravnur/decorators';
import { Vue } from 'vue-class-component';

import './ellipsis.scss';

const DEFAULT_SIZE = 50;
const CN = 'ellipsis';

export default class Ellipsis extends Vue {
  @Prop({ type: String, required: true }) value: string;
  @Prop({ type: Number, default: DEFAULT_SIZE }) size: number;

  private isCollapsed = true;

  private get prepared(): string {
    return this.isCollapsed ? ellipsis(this.value, this.size) : this.value;
  }

  private get isCollapsable(): boolean {
    return this.value.length > this.size;
  }

  private toggle() {
    this.isCollapsed = !this.isCollapsed;
  }

  render() {
    return (
      <p class={CN}>
        <span class={`${CN}__text`}>{this.prepared}</span>
        {this.renderButton()}
      </p>
    );
  }

  private renderButton() {
    if (this.isCollapsable) {
      return (
        <r-button class={`${CN}__more-btn`} color="primary" mode="frameless" onclick={this.toggle}>
          <l10n group="common" tkey={this.isCollapsed ? 'ellipsis__more' : 'ellipsis__less'} />
        </r-button>
      );
    }
  }
}
