export type Folder = {
  name: string;
  description?: string;
  isDefault: boolean;
  parentId?: Nullable<string>;
  mediaCount?: number;
} & Entity;

export type Folder$Node = {
  id: string;
  name: string;
  description: string;
  expanded: boolean;
  isDefault: boolean;
  children?: Nullable<Folder$Node[]>;
  mediaCount?: number;

  isRoot?: boolean;
};

export type Folder$Linear = {
  id: string;
  name: string;
  level: number;
};

export enum Folder$ActionType {
  REMOVE = 'remove',
  EDIT = 'edit',
  MOVE = 'move',
  SHARE = 'share',
}

export type Folder$Action = {
  name: string;
  type: Folder$ActionType;
};
