import MockAdapter from 'axios-mock-adapter/types';
import factory from './mock-factory';
import { faker } from '@faker-js/faker';
import { _generateMockList } from './helper-fakers';

const data = _generateMockList(10, _generateFolder);

export default function mocker(adapter: MockAdapter) {
  const { _load, _operation, _get, _create, _edit } = factory(data, 'id', ['name']);
  adapter.onGet(/\/favorites\/folder$/).reply(_load);
  adapter.onDelete(/\/favorites\/folder\/\d+$/).reply(_operation);
  adapter.onPut(/\/favorites\/folder\/\d+$/).reply(_edit);
  adapter.onPost(/\/favorites\/folder$/).reply(_create);
  adapter.onGet(/\/favorites\/folder\/\d+$/).reply(_get);
}

function _generateFolder() {
  return {
    id: `${faker.datatype.number()}`,
    name: faker.lorem.words(2),
    isDefault: false,
  };
}
