import { ComputedRef, onUnmounted, Ref, unref, watch } from 'vue';

type ResizeObserverSize = {
  readonly inlineSize: number;
  readonly blockSize: number;
};

type ResizeObserverEntry = {
  readonly target: Element;
  readonly contentRect: DOMRectReadOnly;
  readonly borderBoxSize?: ReadonlyArray<ResizeObserverSize>;
  readonly contentBoxSize?: ReadonlyArray<ResizeObserverSize>;
  readonly devicePixelContentBoxSize?: ReadonlyArray<ResizeObserverSize>;
};

type ResizeObserverCallback = (
  entries: ReadonlyArray<ResizeObserverEntry>,
  observer?: ResizeObserver
) => void;

type ResizeObserverOptions = {
  box?: 'content-box' | 'border-box';
};

type MaybeRef<T> = T | Ref<T> | ComputedRef<T>;

declare class ResizeObserver {
  constructor(callback: ResizeObserverCallback);
  disconnect(): void;
  observe(target: Element, options?: ResizeObserverOptions): void;
  unobserve(target: Element): void;
}

export function useResizeObserver(
  target: MaybeRef<Element | undefined>,
  callback: ResizeObserverCallback,
  options: ResizeObserverOptions = {}
) {
  const { ...observerOptions } = options;
  let observer: ResizeObserver | undefined;
  const isSupported = window && 'ResizeObserver' in window;

  const cleanup = () => {
    if (observer) {
      observer.disconnect();
      observer = undefined;
    }
  };

  const stopWatch = watch(
    () => unref(target),
    (el) => {
      cleanup();

      if (isSupported && window && el instanceof Element) {
        observer = new window.ResizeObserver(callback);
        observer.observe(el, observerOptions);
      }
    },
    { immediate: true, flush: 'post' }
  );

  const stop = () => {
    cleanup();
    stopWatch();
  };

  onUnmounted(stop);

  return {
    isSupported,
    stop,
  };
}
