import { Vue, Options, prop } from 'vue-class-component';

import { Folder, Folder$Node, Folder$Action } from '../../types/Folder';

import './folders-tree.scss';

const CN = 'folders-tree';

class Props {
  folders: Array<Folder$Node>;
  selected?: Nullable<Folder>;
  actions?: Array<Folder$Action>;
  linear = prop({ default: false });
  showItemsCount = prop({ default: false });

  onAction?: (data: { action: Folder$Action; node: Folder$Node }) => void;
  onSelect?: (node: Folder$Node) => void;
  onDropTo?: (node: Folder$Node) => void;
}

@Options({
  emits: ['action', 'select', 'drop-to'],
})
export default class FoldersTree extends Vue.with(Props) {
  dragTo: Folder$Node | null = null;

  select(node: Folder$Node) {
    this.$emit('select', node);
    node.expanded = true;
  }

  toggle(node: Folder$Node) {
    node.expanded = !node.expanded;
  }

  doAction(action: { action: Folder$Action; node: Folder$Node }) {
    this.$emit('action', action);
  }

  detectActionsForNode(node: Folder$Node): Folder$Action[] | undefined {
    const { actions } = this;

    if (!actions) {
      return void 0;
    }

    if (node.isDefault) {
      const predicate = (a: Folder$Action) => a.type !== 'remove' && a.type !== 'move';
      return actions.filter(predicate);
    }

    return actions;
  }

  onDrop() {
    const { dragTo } = this;
    if (dragTo) {
      this.$emit('drop-to', dragTo);
    }
    this.dragTo = null;
  }

  dropTo(node: Folder$Node) {
    this.$emit('drop-to', node);
  }

  onDragOver(node: Folder$Node, event: DragEvent) {
    if (node.isRoot) {
      return;
    }
    this.dragTo = node;
    event.preventDefault();
  }

  onDragLeave() {
    this.dragTo = null;
  }

  render() {
    const cn = {
      [CN]: true,
      [`${CN}--linear`]: this.linear,
    };
    return (
      <div class={cn}>
        {this.folders
          // default folder first
          .sort((a, b) => (a.isDefault === b.isDefault ? 0 : a.isDefault ? -1 : 1))
          .map(this.renderNode)}
      </div>
    );
  }

  private renderNode(node: Folder$Node) {
    const isCurrent = this.selected?.id === node.id;
    const isHover = node === this.dragTo;
    const titleCN = {
      [`${CN}__node-title`]: true,
      [`${CN}__node-title--current`]: isCurrent,
    };
    return (
      <div key={node.id} class={`${CN}__node`}>
        {this.renderExpander(node)}

        <r-button
          class={titleCN}
          color={isHover ? 'primary' : 'black'}
          hover={isHover}
          icon="folder"
          mode="frameless"
          onclick={() => this.select(node)}
          ondragleave={this.onDragLeave}
          ondragover={($event) => this.onDragOver(node, $event)}
          ondrop={this.onDrop}
          title={node.description}
        >
          {this.renderNodeName(node)}
        </r-button>
        {this.renderActions(node)}
        {this.renderChildren(node)}
      </div>
    );
  }

  private renderNodeName(node: Folder$Node) {
    if (this.showItemsCount) {
      return `${node.name} (${node.mediaCount ? node.mediaCount : 0})`;
    }

    return node.name;
  }

  private renderActions(node: Folder$Node) {
    if (this.actions) {
      return (
        <more-actions
          class={`${CN}__actions`}
          options={this.detectActionsForNode(node)}
          v-slots={{ option: this.renderTreeActionOption }}
          onClick={(action: Folder$Action) => this.doAction({ action, node })}
        />
      );
    }
  }

  private renderTreeActionOption(data: { item: Folder$Node }) {
    return data.item.name;
  }

  private renderChildren(node: Folder$Node) {
    if (node.expanded && node.children && node.children.length) {
      return (
        <FoldersTree
          actions={this.actions}
          class={`${CN}__children`}
          folders={node.children}
          selected={this.selected}
          onAction={this.doAction}
          onDropTo={this.dropTo}
          onSelect={this.select}
        />
      );
    }
  }

  private renderExpander(node: Folder$Node) {
    if (node.children && node.children.length) {
      const toggle = () => this.toggle(node);
      const cn = {
        [`${CN}__expander`]: true,
        [`${CN}__expander--collapsed`]: !node.expanded,
      };
      return (
        <r-button class={cn} color="black" icon="drop-down" mode="frameless" onclick={toggle} />
      );
    }
  }
}
