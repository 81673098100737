import { RouteRecordRaw } from 'vue-router';

import Forbidden from './components/forbidden.vue';
import NotFound from './components/not-found.vue';
import routeGuard from '@/route-guard/route-guard';

export const ROUTES: RouteRecordRaw[] = [
  {
    path: '/playlist/create',
    name: 'CreateMyPlaylist',
    component: () => import('./components/my-account/create-my-playlist/create-my-playlist'),
    meta: { translations: ['media', 'upload', 'media-editor'] },
  },
  {
    path: '/groups/create',
    name: 'CreateMyGroup',
    component: () => import('./components/my-account/groups/create-my-group/create-my-group'),
    meta: {
      translations: ['search_page', 'group'],
    },
    beforeEnter: routeGuard(['isGroups'], ['canCreateGroup']),
  },

  {
    path: '/upload',
    name: 'Upload',
    component: () => import('./components/upload/upload'),
    meta: { translations: ['upload'] },
    beforeEnter: routeGuard([], ['canUploadContent']),
  },
  {
    path: '/videos/:id',
    name: 'VideoEditor',
    component: () => import('./components/my-account/video-editor/video-editor'),
    meta: {
      translations: ['media', 'upload', 'media-editor', 'search_page'],
    },
  },
  {
    path: '/editor/:id',
    name: 'VideoEditors',
    component: () => import('./components/my-account/video-editors/video-editors'),
    meta: {
      translations: ['video-editors', 'trim-editor', 'upload'],
    },
  },
  {
    path: '/documents/:id',
    name: 'DocumentEditor',
    component: () => import('./components/my-account/document-editor/document-editor'),
    meta: {
      translations: ['media', 'upload', 'media-editor'],
    },
  },
  {
    path: '/audios/:id',
    name: 'AudioEditor',
    component: () => import('./components/my-account/audio-editor/audio-editor'),
    meta: {
      translations: ['media', 'upload', 'media-editor', 'search_page'],
    },
  },
  {
    path: '/audio-playlists/:id',
    name: 'AudioPlaylistEditor',
    component: () => import('./components/my-account/audio-playlist-editor/audio-playlist-editor'),
    meta: {
      translations: ['media', 'upload', 'media-editor'],
    },
  },
  {
    path: '/video-playlists/:id',
    name: 'VideoPlaylistEditor',
    component: () => import('./components/my-account/video-playlist-editor/video-playlist-editor'),
    meta: {
      translations: ['media', 'upload', 'media-editor'],
    },
  },

  {
    path: '/account',
    name: 'MyAccount',
    component: () => import('./components/my-account/my-account'),
    redirect: { name: 'MyVideos' },
    children: [
      {
        path: 'videos',
        name: 'MyVideos',
        component: () => import('./components/my-account/my-videos/my-videos'),
        meta: {
          translations: ['search_page', 'my-media'],
        },
      },
      {
        path: 'audios',
        name: 'MyAudios',
        component: () => import('./components/my-account/my-audios/my-audios'),
        meta: {
          translations: ['search_page', 'my-media'],
        },
      },
      {
        path: 'documents',
        name: 'MyDocuments',
        component: () => import('./components/my-account/my-documents/my-documents'),
        meta: {
          translations: ['search_page', 'my-media'],
        },
      },
      {
        path: 'playlists',
        name: 'MyPlaylists',
        component: () => import('./components/my-account/my-playlists/my-playlists'),
        meta: {
          translations: ['search_page', 'my-media'],
        },
      },

      {
        path: 'groups',
        name: 'MyGroups',
        component: () => import('./components/my-account/groups/my-groups/my-groups'),
        meta: {
          translations: ['search_page', 'group'],
        },
        beforeEnter: routeGuard(['isGroups']),
      },
      {
        path: 'favorites',
        name: 'MyFavorites',
        component: () => import('./components/my-account/my-favorites/my-favorites'),
        meta: {
          translations: ['search_page', 'my-media', 'my-favorites'],
        },
      },

      {
        path: 'history',
        name: 'ViewingHistory',
        component: () => import('./components/my-account/viewing-history/viewing-history'),
        meta: {
          translations: ['search_page', 'my-media', 'viewing-history'],
        },
      },
      {
        path: 'followings/categories',
        name: 'FollowedCategories',
        component: () => import('./components/my-account/followed-categories/followed-categories'),
        meta: {
          translations: ['search_page', 'followed-categories'],
        },
      },
      {
        path: 'followings/users',
        name: 'FollowedUsers',
        component: () => import('./components/my-account/followed-users/followed-users'),
        meta: {
          translations: ['search_page', 'followed-users'],
        },
      },
      {
        path: 'password',
        name: 'ChangePassword',
        component: () => import('./components/my-account/change-password/change-password'),
        meta: {
          translations: ['search_page', 'change-password'],
        },
      },
      {
        path: 'profile',
        name: 'ProfileSettings',
        component: () => import('./components/my-account/profile-settings/profile-settings'),
        meta: {
          translations: ['profile'],
        },
      },
    ],
  },
  {
    path: '/',
    name: 'Root',
    redirect: { name: 'MyVideos' },
  },

  {
    path: '/404',
    name: '404',
    component: NotFound,
    meta: { translations: ['error-page'] },
  },
  {
    path: '/403',
    name: '403',
    component: Forbidden,
    meta: { translations: ['error-page'] },
  },

  {
    path: '/:pathMatch(.*)*',
    name: '*',
    redirect: { name: '404' },
  },
];
