import { ApplicationOption } from '@ravnur/shared/types/Component';
import { PredefinedMediaProperties } from '@ravnur/shared/types/Media';
import { State, Mutation, Getter } from 'vuex-simple';

import { Site, SiteAndPermissions } from '@/types/Site';
import { FooterSettings } from '@ravnur/shared/types/FooterSettings';

export class ApplicationModule {
  @State()
  public sites: SiteAndPermissions[] = [];

  @State()
  public predefinedMediaProperties: PredefinedMediaProperties = {};

  @State()
  public languages: Locale[] = [];

  @State()
  public publicDomain = '';

  @State()
  public footerSettings: FooterSettings;

  @Getter()
  public get getApplicationSites() {
    return this.sites.map((s) => s.userApplication);
  }

  @Getter()
  public get getApplicationAsOptions() {
    return this.sites.map(_toOption);
  }

  @Getter()
  public get getCurrentSite() {
    if (process.env.NODE_ENV === 'development') {
      const appId = process.env.VUE_APP_APPLICATION_ID;
      return this.getApplicationSites.find((s) => s.id === appId) as Site;
    }

    const hostname = window.location.hostname;
    return this.getApplicationSites.find((s) => {
      return s.portalUrl.includes(hostname);
    }) as Site;
  }

  @Getter()
  public get getPredefinedMediaProperties() {
    return this.predefinedMediaProperties;
  }

  @Mutation()
  public changeSites(sites: SiteAndPermissions[]) {
    this.sites = sites.map((s) => {
      const userApplication = s.userApplication;
      const portalUrl = userApplication.portalUrl ? `${userApplication.portalUrl}/` : '';
      const adminUrl = userApplication.adminUrl ? `${userApplication.adminUrl}/` : '';
      return { ...s, userApplication: { ...userApplication, portalUrl, adminUrl } };
    });
  }

  @Mutation()
  public changeDefaultMediaProperties(props: PredefinedMediaProperties) {
    this.predefinedMediaProperties = props;
  }

  @Mutation()
  public changeLanguages(languages: Locale[]) {
    this.languages = languages;
  }

  @Mutation()
  public setPublicDomain(domain: string) {
    this.publicDomain = domain;
  }

  @Mutation()
  public setFooterSettings(settings: FooterSettings) {
    this.footerSettings = settings;
  }
}

function _toOption({
  userApplication: { id, name, isRoot },
}: SiteAndPermissions): ApplicationOption {
  return { id, label: name, isRoot };
}
