/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-var-requires */
import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';

const mock = new MockAdapter(axios, { delayResponse: 750 });

[
  require('./auth').default,
  require('./media').default,
  require('./source').default,
  require('./log').default,
  require('./languages').default,
  require('./users').default,
  require('./groups').default,
  require('./lookups').default,
  require('./participant').default,
  require('./operation').default,
  require('./processing').default,
  require('./jobs').default,
  require('./favorites-folders').default,
].forEach((fnc) => fnc(mock));
