import { User$Counters, User$Details } from '@ravnur/shared/types/User';
import { State, Getter, Mutation } from 'vuex-simple';

export class UserModule {
  @State()
  public current: Nullable<User$Details> = null;
  @State()
  public counters: Nullable<User$Counters> = null;

  @Getter()
  public get getCurrentUser() {
    return this.current;
  }

  @Getter()
  public get isAuth(): boolean {
    return !!this.current;
  }

  @Mutation()
  public changeUserCounters(counters: User$Counters) {
    this.counters = counters;
  }

  @Mutation()
  public changeCurrentUser(user: Nullable<User$Details>) {
    this.current = user;
  }

  @Mutation()
  public increaseCountOfMyVideos() {
    if (this.counters) {
      this.counters.videos += 1;
    }
  }

  @Mutation()
  public decreaseCountOfMyVideos() {
    if (this.counters) {
      this.counters.videos -= 1;
    }
  }

  @Mutation()
  public increaseCountOfMyAudios() {
    if (this.counters) {
      this.counters.audios += 1;
    }
  }

  @Mutation()
  public decreaseCountOfMyAudios() {
    if (this.counters) {
      this.counters.audios -= 1;
    }
  }

  @Mutation()
  public increaseCountOfMyDocuments() {
    if (this.counters) {
      this.counters.documents += 1;
    }
  }

  @Mutation()
  public decreaseCountOfMyDocuments() {
    if (this.counters) {
      this.counters.documents -= 1;
    }
  }

  @Mutation()
  public increaseCountOfMyPlaylists() {
    if (this.counters) {
      this.counters.playlists += 1;
    }
  }

  @Mutation()
  public decreaseCountOfMyPlaylists() {
    if (this.counters) {
      this.counters.playlists -= 1;
    }
  }

  @Mutation()
  public increaseCountOfMyFavorites() {
    if (this.counters) {
      this.counters.favorites += 1;
    }
  }

  @Mutation()
  public decreaseCountOfMyFavorites() {
    if (this.counters) {
      this.counters.favorites -= 1;
    }
  }

  @Mutation()
  public increaseCountOfMyGroups() {
    if (this.counters) {
      this.counters.groups += 1;
    }
  }

  @Mutation()
  public decreaseCountOfMyGroups() {
    if (this.counters) {
      this.counters.groups -= 1;
    }
  }

  @Mutation()
  public increaseCountOfMyFollowingsUsers() {
    if (this.counters) {
      this.counters.followingsUsers += 1;
    }
  }

  @Mutation()
  public decreaseCountOfMyFollowingsUsers() {
    if (this.counters) {
      this.counters.followingsUsers -= 1;
    }
  }

  @Mutation()
  public increaseCountOfMyFollowingsCategories() {
    if (this.counters) {
      this.counters.followingsCategories += 1;
    }
  }

  @Mutation()
  public decreaseCountOfMyFollowingsCategories() {
    if (this.counters) {
      this.counters.followingsCategories -= 1;
    }
  }
}
