import { Inject, Prop, Watch } from '@ravnur/decorators';
import always from '@ravnur/nanoutils/always';
import { Vue } from 'vue-class-component';

import { CAROUSEL_AUTOREFRESH_INTERVAL } from '../../config/constants';

const DEFAULT_INDEX = 0;
const DEFAULT_ITEMS_COUNT_PER_LINE = 1;

export default class Carousel extends Vue {
  declare $refs: {
    items: HTMLUListElement | undefined;
  };

  @Prop({ type: Array, required: true })
  list: Array<unknown>;
  @Prop({ type: Function, required: false, default: always('') })
  dotTitleGen: () => string;
  @Prop({ type: Boolean, required: false, default: () => true })
  autoScroll: boolean;
  @Prop({ type: Boolean, default: () => true })
  showDotes: boolean;
  @Prop({ type: Boolean, default: () => true })
  widthControl: boolean;
  @Prop({ type: String, default: () => 'frameless' })
  arrowStyle: 'frameless' | 'flat';

  @Inject('layout') private layout: LayoutContext;

  index = DEFAULT_INDEX;
  timerId: Nullable<number> = null;
  width = 0; // eslint-disable-line no-magic-numbers
  isDisabledAnimation = false;
  itemsCountPerLine = DEFAULT_ITEMS_COUNT_PER_LINE;

  count: number = 0; // eslint-disable-line

  get isFirst(): boolean {
    return this.index === DEFAULT_INDEX;
  }

  get isLast(): boolean {
    return this.index === this.count - 1; // eslint-disable-line no-magic-numbers
  }

  get totalWidth(): number {
    return this.count * this.width;
  }

  get isReady(): boolean {
    return !!this.width;
  }

  get offset(): number {
    return this.index * this.width;
  }

  get layoutWidth(): number {
    return this.layout.width;
  }

  isVisible(idx: number) {
    const from = this.index * this.itemsCountPerLine;
    const to = from + this.itemsCountPerLine;

    return idx >= from && idx < to;
  }

  prev() {
    --this.index;
  }

  next() {
    ++this.index;
  }

  goto(idx: number) {
    this.index = idx;
  }

  carouselTick() {
    if (this.isLast) {
      this.goto(DEFAULT_INDEX);
    } else {
      this.next();
    }
  }

  disabledAnimation() {
    this.isDisabledAnimation = true;
  }

  enabledAnimation() {
    this.isDisabledAnimation = false;
  }

  @Watch('list')
  @Watch('layoutWidth')
  onChangeLayoutWidth() {
    this.disabledAnimation();
    this.$nextTick(() => {
      this.index = DEFAULT_INDEX;
      const count = this.list.length;
      this.width = this.$el.clientWidth;
      this.count = count;
      this.$nextTick(this._afterRepaint);
    });
  }

  _afterRepaint() {
    const ul = this.$refs.items;
    if (!ul) {
      return;
    }
    const items = ul.children;
    const count = this.count;
    const li = items.item(0);
    if (li && li instanceof HTMLElement) {
      this.itemsCountPerLine = Math.round(this.width / li.clientWidth);
      this.count = Math.ceil(count / this.itemsCountPerLine);
    }
    this.$nextTick(this.enabledAnimation);
  }

  stopAutoRefreshing() {
    const timerId = this.timerId;
    if (timerId) {
      clearInterval(timerId);
      this.timerId = null;
    }
  }

  startAutoRefreshing() {
    this.stopAutoRefreshing();
    if (this.autoScroll) {
      this.timerId = window.setInterval(this.carouselTick, CAROUSEL_AUTOREFRESH_INTERVAL);
    }
  }

  @Watch('autoScroll')
  autoScrollIfNeeded() {
    if (this.autoScroll) {
      this.startAutoRefreshing();
    } else {
      this.stopAutoRefreshing();
    }
  }

  created() {
    this.autoScrollIfNeeded();
  }

  mounted() {
    this.onChangeLayoutWidth();
    this.$nextTick(this.onChangeLayoutWidth);
  }

  destroyed() {
    this.stopAutoRefreshing();
  }
}
