import { BaseRepository } from '@ravnur/http';
import pathes from '@ravnur/shared/config/pathes';
import { User, User$Details } from '@ravnur/shared/types/User';

import { FollowType } from '@/types/FlowType';
import { Site } from '@/types/Site';

export default class UsersRepository extends BaseRepository<
  User$Details,
  Record<string, unknown>,
  User[]
> {
  constructor() {
    super(pathes.USERS);
  }

  public follow(user: User, data: { type: FollowType }) {
    return this.action('follow', data, user.id);
  }

  public unfollow(user: User) {
    return this.undo('follow', {}, user.id);
  }

  public changePassword(user: User, data: { oldPassword: string; newPassword: string }) {
    return this.action('password', data, user.id);
  }

  public leave(user: User, sites: Site[]) {
    return this.action('leave', sites, user.id);
  }

  public removeUserpic(user: User) {
    return this.undo('userpic', {}, user.id);
  }
}
