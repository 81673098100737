import { UploadPermission } from '../../types/Upload';
import { UserPermissionsMapper, UserPermissions } from '../../types/UserPermissions';

type PermissionsConverter = (value: string) => boolean;

export class UserPermissionsHelper {
  constructor(private readonly permissions: Nullable<UserPermissions>[]) {}

  public get canShareEmailAndLink(): boolean {
    return hasUserPermissions(this.permissions, UserPermissionsMapper.shareLink);
  }

  public get canMediaExternalSharing(): boolean {
    return hasUserPermissions(this.permissions, UserPermissionsMapper.enablePublicPage);
  }

  public get canShareEmbedCode(): boolean {
    return hasUserPermissions(this.permissions, UserPermissionsMapper.shareEmbedCode);
  }

  public get canDownloadContent(): boolean {
    return hasUserPermissions(this.permissions, UserPermissionsMapper.downloadContent);
  }

  public get canEditTags(): boolean {
    return hasUserPermissions(this.permissions, UserPermissionsMapper.tag);
  }

  public get canEditForeignComments(): boolean {
    return hasUserPermissions(this.permissions, UserPermissionsMapper.editComments);
  }

  public get hasAccessToAdmin(): boolean {
    return hasUserPermissions(this.permissions, UserPermissionsMapper.accessToAdministration);
  }

  public get canCreateGroup(): boolean {
    return hasUserPermissions(this.permissions, UserPermissionsMapper.createGroup);
  }

  public get canUploadContentWithoutReview(): boolean {
    return hasUserPermissions(
      this.permissions,
      UserPermissionsMapper.upload,
      (val: string) => val === UploadPermission.WITHOUT_REVIEW
    );
  }

  public get canUploadContent(): boolean {
    return hasUserPermissions(
      this.permissions,
      UserPermissionsMapper.upload,
      (val: string) => val !== UploadPermission.NONE
    );
  }

  public get canRunAudioIndexing(): boolean {
    return hasUserPermissions(this.permissions, UserPermissionsMapper.runAudioIndexing);
  }
}

function hasUserPermissions(
  permissions: Nullable<UserPermissions>[],
  name: keyof UserPermissions,
  converter: PermissionsConverter = _boolConverter
) {
  return permissions.some((perms) => (perms ? converter(perms[name]) : false));
}

function _boolConverter(value: string): boolean {
  return value === 'True';
}
