import { HttpService, BaseRepository } from '@ravnur/http';

import pathes from '@ravnur/shared/config/pathes';
import { Job$Response } from '@ravnur/shared/src/types/Job';
import { Job } from '@ravnur/shared/types/Job';

type JobsRepositoryQueryParams = {
  id: string[];
  mediaId: string[];
};

export default class JobsRepository extends BaseRepository<Job, JobsRepositoryQueryParams> {
  constructor() {
    super(pathes.JOB);
  }

  public async monitor(params: Record<string, unknown> = {}): Promise<Job$Response[]> {
    return HttpService.get(`${this._resource}/current`, { params });
  }
}
