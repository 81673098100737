import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 3,
  class: "carousel__dotes"
}

export function render(_ctx, _cache) {
  const _component_r_button = _resolveComponent("r-button")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["carousel", { 'carousel--no-animation': _ctx.isDisabledAnimation }]),
    onMouseenter: _cache[0] || (_cache[0] = (...args) => (_ctx.stopAutoRefreshing && _ctx.stopAutoRefreshing(...args))),
    onMouseleave: _cache[1] || (_cache[1] = (...args) => (_ctx.startAutoRefreshing && _ctx.startAutoRefreshing(...args)))
  }, [
    (_ctx.isReady)
      ? (_openBlock(), _createElementBlock("ul", {
          key: 0,
          class: "carousel__list",
          style: _normalizeStyle({ width: `${_ctx.totalWidth}px`, transform: `translateX(-${_ctx.offset}px)` }),
          ref: "items"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, idx) => {
            return (_openBlock(), _createElementBlock("li", {
              class: _normalizeClass(["carousel__item", { 'carousel__item--visible': _ctx.isVisible(idx) }]),
              key: idx,
              style: _normalizeStyle({ width: _ctx.widthControl ? `${_ctx.width}px` : '' })
            }, [
              _renderSlot(_ctx.$slots, "item", { item: item })
            ], 6))
          }), 128))
        ], 4))
      : _createCommentVNode("", true),
    (_ctx.count > 1)
      ? (_openBlock(), _createBlock(_component_r_button, {
          key: 1,
          class: _normalizeClass(["carousel__arrow carousel__arrow--left", { 'carousel__arrow--flat': _ctx.arrowStyle === 'flat' }]),
          mode: "frameless",
          size: "lg",
          color:  _ctx.arrowStyle === 'flat' ? 'black' : 'grey',
          icon: "arrow-left",
          onClick: _ctx.prev,
          disabled: _ctx.isFirst
        }, null, 8, ["color", "class", "onClick", "disabled"]))
      : _createCommentVNode("", true),
    (_ctx.count > 1)
      ? (_openBlock(), _createBlock(_component_r_button, {
          key: 2,
          class: _normalizeClass(["carousel__arrow carousel__arrow--right", { 'carousel__arrow--flat': _ctx.arrowStyle === 'flat' }]),
          mode: "frameless",
          size: "lg",
          color:  _ctx.arrowStyle === 'flat' ? 'black' : 'grey',
          icon: "arrow-right",
          onClick: _ctx.next,
          disabled: _ctx.isLast
        }, null, 8, ["color", "class", "onClick", "disabled"]))
      : _createCommentVNode("", true),
    (_ctx.showDotes && _ctx.count > 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, idx) => {
            return (_openBlock(), _createBlock(_component_r_button, {
              class: _normalizeClass(["carousel__dot", { 'carousel__dot--current': _ctx.index === idx }]),
              key: idx,
              onClick: $event => (_ctx.goto(idx)),
              mode: "frameless",
              color: "white",
              size: "xs",
              title: _ctx.dotTitleGen(item),
              icon: "dot"
            }, null, 8, ["onClick", "title", "class"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 34))
}