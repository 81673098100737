import { convertFromError } from '@ravnur/notifications/convertFromError';
import { showErrorNotification } from '@ravnur/notifications/service';

import uidGenerator from '@ravnur/shared/helpers/uid.generator';
import urlGenerator from '@ravnur/shared/helpers/url.generator';
import { Media$Types } from '@ravnur/shared/types/Media';
import { IUploadStore, UploadInfo, UploadStatus } from '@ravnur/shared/types/Upload';
import doubleExtensionChecker from '@ravnur/helpers/double-extension-checker';

import Resumable from '@/libs/Resumable';
import Logger from '@/logger';
import MyMediaRepository from '@/repositories/my-media-repository';
import create from '@/transformers/media/create';
import * as Sentry from '@sentry/vue';
import { $processPageException } from './error-handler';

const logger = new Logger('Resumable');
const repository = new MyMediaRepository();
const PATH =
  process.env.VUE_APP_MOCK_API === 'yes'
    ? 'http://localhost:5000/files/test'
    : urlGenerator.generate(`/upload`);

interface UploadAddFilePayload {
  mediaId: string;
  mediaTitle: string;
  keepSubResources: boolean;
}

export function factory(store: IUploadStore) {
  const resumable = new Resumable({
    fileType: [], // Update when used in component. Allowed files comes from BE response of auth endpoint
    target: PATH,
    chunkSize: 1024 * 1024,
    maxChunkRetries: 3,
    chunkRetryInterval: 1000,
    simultaneousUploads: 1,
    permanentErrors: [400, 401, 403, 409, 415, 500, 501],
    generateUniqueIdentifier: () => uidGenerator('xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'),
    chunkNumberParameterName: 'chunkNumber',
    chunkSizeParameterName: 'chunkSize',
    currentChunkSizeParameterName: 'currentChunkSize',
    totalSizeParameterName: 'totalSize',
    typeParameterName: 'type',
    identifierParameterName: 'identifier',
    fileNameParameterName: 'filename',
    relativePathParameterName: 'relativePath',
    totalChunksParameterName: 'totalChunks',
    testChunks: false,
    query(file) {
      const info = store.cacheByUid[file.uniqueIdentifier];
      const mediaId = (info && info.mediaId) || null;
      const autoStartProcess = (info && info.autoStartProcess) || true;
      let keepSubResources = info && info.keepSubResources;
      keepSubResources = typeof keepSubResources === 'boolean' ? keepSubResources : false;
      return { mediaId, attachmentType: 'sources', keepSubResources, autoStartProcess };
    },
  });

  resumable.on('fileAdded', async (file, payload: Event | UploadAddFilePayload) => {
    const mediaId = payload instanceof Event ? null : payload.mediaId;
    const mediaTitle = payload instanceof Event ? '' : payload.mediaTitle;
    const keepSubResources = payload instanceof Event ? false : payload.keepSubResources;
    const uid = file.uniqueIdentifier;
    const mediaType = getFileType(
      file.fileName.split('.').pop() || '',
      store.allowedVideoFormats,
      store.allowedAudioFormats
    );

    const allowedFormats = [...store.allowedVideoFormats, ...store.allowedAudioFormats];

    const hasDoubleExtension = doubleExtensionChecker(file.fileName, allowedFormats);

    if (hasDoubleExtension) {
      $processPageException(new Error('Invalid file - double extensions are not permitted'));

      return;
    }

    try {
      if (mediaId) {
        // we can upload new version for existing media
        store.start(
          createUploadInfo({
            uid,
            mediaId,
            mediaType,
            isRefreshExistingSource: true,
            keepSubResources,
            mediaTitle,
          })
        );

        setTimeout(resumable.upload);
      } else {
        const title = file.fileName.substring(0, file.fileName.lastIndexOf('.')).substring(0, 150);
        const m = create({
          title,
          type: mediaType,
        });

        store.start(
          createUploadInfo({
            uid,
            mediaId: '',
            mediaType,
            mediaTitle: title,
          })
        );

        const { id } = await repository.save(m);

        if (!id) {
          return;
        }

        store.addMediaId({ uid, mediaId: id });
        setTimeout(resumable.upload);
      }
    } catch (e) {
      convertFromError(e).forEach(showErrorNotification);
    }
  });

  resumable.on('fileSuccess', (file) => {
    const isPaused = !file.isComplete() && !file.isUploading();

    store.handleProgressEvent({
      uid: file.uniqueIdentifier,
      progress: 100,
      isPaused,
      status: UploadStatus.FINISHED,
    });
  });

  resumable.on('fileProgress', (file) => {
    const isPaused = !file.isComplete() && !file.isUploading();
    const progress = file.progress(false) * 100;

    store.handleProgressEvent({
      uid: file.uniqueIdentifier,
      progress,
      isPaused,
      status: UploadStatus.PROCESSING,
    });
  });

  resumable.on('fileError', (file, message) => {
    const isPaused = !file.isComplete() && !file.isUploading();
    const progress = file.progress(false) * 100;

    store.handleProgressEvent({
      uid: file.uniqueIdentifier,
      progress,
      status: UploadStatus.FAILED,
      isPaused,
    });

    logger.error('error on uploading file', file, message);

    Sentry.captureException(new Error('Upload failed'), (scope) => {
      const { fileName, size, uniqueIdentifier, resumableObj } = file;

      scope.clear();
      scope.setExtra('file', {
        fileName,
        size,
        uniqueIdentifier,
        progress: resumableObj.progress(),
      });

      return scope;
    });
  });

  return resumable;
}

function createUploadInfo(
  info: RequiredBy<UploadInfo, 'uid' | 'mediaId' | 'mediaType' | 'mediaTitle'>
): UploadInfo {
  return {
    isPaused: true,
    progress: 0,
    isSubmitted: false,
    isRefreshExistingSource: false,
    status: UploadStatus.INITIALIZED,
    ...info,
  };
}

function getFileType(ext: string, videoTypes: string[], audioTypes: string[]) {
  if (videoTypes.includes(ext.toLowerCase())) return Media$Types.VIDEO;
  if (audioTypes.includes(ext.toLowerCase())) return Media$Types.AUDIO;

  return Media$Types.VIDEO;
}
