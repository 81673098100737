import { ComputedRef, onUnmounted, Ref, unref, watch } from 'vue';

interface InferEventTarget<Events> {
  addEventListener(event: Events, fn?: any, options?: any): any;
  removeEventListener(event: Events, fn?: any, options?: any): any;
}

export type WindowEventName = keyof WindowEventMap;
export type DocumentEventName = keyof DocumentEventMap;

export type GeneralEventListener<E = Event> = {
  (evt: E): void;
};

const noop = () => void 0;
type Fn = () => void;
type MaybeRef<T> = T | Ref<T> | ComputedRef<T>;
const isString = (val: unknown): val is string => typeof val === 'string';

export function useEventListener<E extends keyof WindowEventMap>(
  event: E,
  listener: (this: Window, ev: WindowEventMap[E]) => unknown,
  options?: boolean | AddEventListenerOptions
): Fn;

export function useEventListener<E extends keyof WindowEventMap>(
  target: Window,
  event: E,
  listener: (this: Window, ev: WindowEventMap[E]) => unknown,
  options?: boolean | AddEventListenerOptions
): Fn;

export function useEventListener<E extends keyof DocumentEventMap>(
  target: Document,
  event: E,
  listener: (this: Document, ev: DocumentEventMap[E]) => unknown,
  options?: boolean | AddEventListenerOptions
): Fn;

export function useEventListener<Names extends string, EventType = Event>(
  target: InferEventTarget<Names>,
  event: Names,
  listener: GeneralEventListener<EventType>,
  options?: boolean | AddEventListenerOptions
): Fn;

export function useEventListener<EventType = Event>(
  target: MaybeRef<EventTarget | null | undefined>,
  event: string,
  listener: GeneralEventListener<EventType>,
  options?: boolean | AddEventListenerOptions
): Fn;

export function useEventListener(...args: any[]) {
  let target: MaybeRef<EventTarget> | undefined;
  let event: string;
  let listener: any;
  let options: any;

  if (isString(args[0])) {
    [event, listener, options] = args;
    target = window;
  } else {
    [target, event, listener, options] = args;
  }

  if (!target) return noop;

  let cleanup: Fn = noop;

  const stopWatch = watch(
    () => unref(target),
    (el) => {
      cleanup();
      if (!(el instanceof HTMLElement)) return;

      el.addEventListener(event, listener, options);

      cleanup = () => {
        el.removeEventListener(event, listener, options);
        cleanup = noop;
      };
    },
    { immediate: true, flush: 'post' }
  );

  const stop = () => {
    stopWatch();
    cleanup();
  };

  onUnmounted(stop);

  return stop;
}
