import { Vue, Options } from 'vue-class-component';

import './hidden-file-selector.scss';

const CN = 'hidden-file-selector';

class Props {
  accept?: string;

  onFileChange?: (file: Nullable<File>) => void;
}

@Options({
  emits: ['file-change'],
})
export default class HiddenFileSelector extends Vue.with(Props) {
  declare $refs: {
    field: HTMLInputElement;
  };

  public browse() {
    this.$refs.field.click();
  }

  public isSelected(): boolean {
    return !!this.$refs.field.value;
  }

  public clear() {
    this.$refs.field.value = '';
  }

  private handleChangeEvent() {
    const file = getFirstFile(this.$refs.field);
    this.$emit('file-change', file);
  }

  render() {
    return (
      <input
        ref="field"
        accept={this.accept}
        aria-hidden={true}
        class={CN}
        name="file"
        type="file"
        onChange={this.handleChangeEvent}
      />
    );
  }
}

function getFirstFile(input: Nullable<HTMLInputElement>) {
  return input?.files?.item(0) || null;
}
