import { MutationPayload } from 'vuex';
import createMutationsSharer from 'vuex-shared-mutations';
import { createVuexStore } from 'vuex-simple';

import { RooStore } from './store';

export const instance = new RooStore();

const SHARED_MODULES: Array<keyof RooStore> = [
  'user',
  'upload',
  'jobs',
  'application',
  'startLogout',
  'addLogoutCode',
];

export default createVuexStore(instance, {
  strict: process.env.NODE_ENV !== 'production',
  plugins: [
    createMutationsSharer({
      predicate: (mutation: MutationPayload) => {
        const [module] = mutation.type.split('/');
        return SHARED_MODULES.some((m) => m === module);
      },
    }),
  ],
});
