import and from '@ravnur/nanoutils/and';
import ary from '@ravnur/nanoutils/ary';
import flow from '@ravnur/nanoutils/flow';
import has from '@ravnur/nanoutils/has';
import isSame from '@ravnur/nanoutils/isSame';
import last from '@ravnur/nanoutils/last';
import map from '@ravnur/nanoutils/map';
import not from '@ravnur/nanoutils/not';
import omit from '@ravnur/nanoutils/omit';
import or from '@ravnur/nanoutils/or';
import prop from '@ravnur/nanoutils/prop';
import lowerFirst from '@ravnur/nanoutils/string/lowerFirst';
import { SortType } from '@ravnur/shared/types/SortBy';
import { AxiosRequestConfig } from 'axios';

import { startOperation } from './operation';

import { finder } from '@/helpers/finder';

const defaultComparator = () => 0;

// eslint-disable-next-line @typescript-eslint/ban-types
export default function factory<E extends {}>(
  collection: E[],
  id: keyof SubType<E, string>,
  searchFields: Array<keyof SubType<E, string>> = []
) {
  const p1 = _predicateFactory(searchFields);
  const createPredicateWithRealParams = flow(
    omit(['offset', 'count', 'sortType', 'sortField']) as any,
    p1
  );

  function _load(config: AxiosRequestConfig) {
    const params = config.params as Dictionary<string | number>;
    console.warn(params); // tslint:disable-line

    const predicate = createPredicateWithRealParams(params as any);
    const { sortField, sortType } = params;
    const comparator =
      sortField && sortType
        ? _createComparator(sortField, sortType as SortType)
        : defaultComparator;

    const resp = collection.filter(predicate).sort(comparator);

    const offset = +params.offset || 0;
    const count = +params.count;

    const total = resp.length;
    const items = count ? resp.slice(offset, offset + +params.count) : [...resp];

    return [200, { items, total }];
  }

  function _create(config: AxiosRequestConfig) {
    const entityId = `${Date.now()}`;
    const data = JSON.parse(config.data);
    console.warn(data); // tslint:disable-line
    const entity = { ...data, [id]: entityId };
    const op = startOperation(entityId);
    collection.push(entity as any);
    return [200, op];
  }

  function _get(config: AxiosRequestConfig) {
    const entityId = _id(config);
    const [entity] = collection.filter((e) => (e[id] as any) === entityId);
    return entity ? [200, entity] : [404];
  }

  function _edit(config: AxiosRequestConfig) {
    const entityId = _id(config);
    if (!entityId) {
      return [404];
    }
    const data = JSON.parse(config.data);
    collection.forEach((e) => {
      if ((e[id] as any) === entityId) {
        Object.assign(e, data);
      }
    });
    const op = startOperation(entityId);
    return [200, op];
  }

  function _operation(config: AxiosRequestConfig) {
    console.warn(config.data); // tslint:disable-line
    const op = startOperation(`${Date.now()}`);
    return [200, op];
  }

  return { _load, _create, _get, _edit, _operation };
}

function _id(config: AxiosRequestConfig): Nullable<string> {
  const url = config.url;
  if (!url) {
    return null;
  }
  return last(url.split('/')) || null;
}

// eslint-disable-next-line @typescript-eslint/ban-types
function _predicateFactory<E extends {}>(searchFields: Array<keyof SubType<E, string>>) {
  return function _createPredicate(params: Dictionary<string | number | string[]>): Predicate<E> {
    const predicates: Array<Predicate<E>> = [];

    for (const key in params) {
      if (key === 'q') {
        predicates.push(finder(`${params[key]}`, searchFields));
      } else if (Object.prototype.hasOwnProperty.call(params, key)) {
        const val = params[key];
        const arr: Array<string | number> = val instanceof Array ? val : [val];
        const cond = or(map(ary(1, isSame), arr));
        const isEq = flow(prop(key), cond);
        predicates.push(or([not(has(key)), isEq]));
      }
    }

    return and(predicates);
  };
}

function _createComparator<P extends string, T extends { [k in P]: string | number }>(
  p: P | number,
  type: SortType
) {
  if (p == null || typeof p === 'number') {
    return defaultComparator;
  }
  const desc = type === SortType.DESC;
  const key: P = lowerFirst(p) as P;
  return function _compare(a1: T, a2: T) {
    const v1 = a1[key];
    const v2 = a2[key];

    const delta = v1 > v2 ? 1 : v2 < v1 ? -1 : 0;
    const koeff = desc ? -1 : 1;
    return delta * koeff;
  };
}
