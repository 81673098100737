import MockAdapter from 'axios-mock-adapter/types';
import { faker } from '@faker-js/faker';
import { _generateMockList } from './helper-fakers';
import { VideoSource } from '@ravnur/shared/types/VideoSource';
import { Media$Types } from '@ravnur/shared/types/Media';

// From https://gist.github.com/jsturgis/3b19447b304616f18657
const publicVideoSources = [
  'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
  'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4',
  'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4',
  'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4',
  'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4',
  'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/SubaruOutbackOnStreetAndDirt.mp4',
  'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/TearsOfSteel.mp4',
  'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/WeAreGoingOnBullrun.mp4',
];

export default function mocker(mock: MockAdapter) {
  mock.onGet(/\/api\/v1\.0\/source\/\d+/).reply(_get);
}

function _get() {
  return [200, _generateMediaSources()];
}

function _loadSources() {
  return _generateMockList(4, _generateSource);
}

function _generateSource() {
  return {
    src: faker.helpers.arrayElement(publicVideoSources),
    name: faker.helpers.arrayElement(['360', '720', '480', '240']),
    default: true,
    type: 'video/mp4',
  };
}

function _generateMediaSources(): { mediaSources: VideoSource[]; mediaType: Media$Types } {
  return {
    mediaSources: [_generateMediaSource()],
    mediaType: Media$Types.VIDEO,
  };
}

export function _generateMediaSource() {
  return {
    id: `${faker.datatype.number()}`,
    title: faker.lorem.sentence(),
    sources: _loadSources(),
    type: 'video/mp4',
    poster: faker.image.imageUrl(),
    preview: null,
    cc: [],
    annotations: [],
    chapters: [],
    src: '',
  };
}
