import { Watch } from '@ravnur/decorators';
import $t from '@ravnur/l10n/$t';
import ModalService from '@ravnur/modal';
import LoadableSection from '@ravnur/shared/components/loadable-section/loadable-section';
import { Folder$Action, Folder$ActionType, Folder } from '@ravnur/shared/types/Folder';
import { Vue, prop } from 'vue-class-component';

import FavoritesFolderEditorModal from '../favorites-folder-editor-modal/favorites-folder-editor-modal';

import FavoritesFolders from './favorites-folders';

import './my-favorites-folders.scss';

const CN = 'my-favorites-folders';

class Props {
  showCreateButton = prop({ default: true });
}

export default class MyFavoritesFolders extends Vue.with(Props) {
  private removing = false;

  private get selectedFolderId(): Nullable<string> {
    const { name, query } = this.$route;
    if (name === 'MyFavorites' && query) {
      const { folderId } = query;
      return typeof folderId === 'string' ? folderId : null;
    }
    return null;
  }

  private get folders() {
    return this.store.favoritesFolders.list;
  }

  private get cache() {
    return this.store.favoritesFolders.cache;
  }

  private get folder() {
    return this.selectedFolderId ? this.cache[this.selectedFolderId] : null;
  }

  private get actions(): Folder$Action[] {
    return [
      { type: Folder$ActionType.EDIT, name: $t('common', 'folder__edit') },
      {
        type: Folder$ActionType.REMOVE,
        name: $t('common', 'folder__remove'),
      },
    ];
  }

  private get isFolderLoaded() {
    return !this.store.favoritesFolders.loading;
  }

  public async createNewFolder() {
    const res = await ModalService.showModal(FavoritesFolderEditorModal, {}).catch(() => false);

    if (!res) return;

    this.store.favoritesFolders.load();
  }

  render() {
    return (
      <LoadableSection loading={this.removing}>
        {this.renderCreateButton()}
        <FavoritesFolders
          actions={this.actions}
          folder={this.folder}
          onAction={this.doAction}
          onSelect={this.selectFolder}
        />
      </LoadableSection>
    );
  }

  @Watch('isFolderLoaded', { immediate: true })
  protected handleFoldersLoaded() {
    if (this.$route.name !== 'MyFavorites') return;

    const folder = this.store.favoritesFolders.list.find((f) => f.isDefault);

    if (this.isFolderLoaded && !this.selectedFolderId && folder) {
      this.selectFolder(folder);
    }
  }

  private renderCreateButton() {
    if (!this.showCreateButton) {
      return;
    }
    return (
      <r-button
        class={`${CN}__create-btn`}
        color="primary"
        icon="add"
        mode="frameless"
        onclick={this.createNewFolder}
      >
        <l10n group="common" tkey="folder__create_title" />
      </r-button>
    );
  }

  private selectFolder(folder: Folder) {
    this.$router.push({ name: 'MyFavorites', query: { folderId: folder.id } });
    this.$emit('folder-select');
  }

  private doAction({ action, folder }: { action: Folder$ActionType; folder: Folder }) {
    if (action === Folder$ActionType.EDIT) {
      this.edit(folder);
    } else if (action === Folder$ActionType.REMOVE) {
      this.remove(folder);
    }
  }

  private async edit(folder: Folder) {
    await ModalService.showModal(FavoritesFolderEditorModal, { folder }).catch(() => false);
  }

  private async remove(folder: Folder) {
    const msg = $t('my-favorites', 'folder__remove-confirmer');
    const modalOptions = {
      header: $t('my-favorites', 'folder__remove-confirmer-header'),
    };

    const res = await ModalService.confirm(msg, modalOptions).catch(() => false);

    if (!res) {
      return;
    }

    try {
      this.removing = true;
      await this.store.favoritesFolders.remove(folder);
      const [df] = this.folders.filter((f) => f.isDefault);
      this.selectFolder(df);
    } catch (e) {
      this.$processException(e);
    } finally {
      this.removing = false;
    }
  }
}
