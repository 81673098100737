import PATHES from '../config/pathes';

const URL_BASE = _removeEndSlashes(process.env.VUE_APP_API_URL || '');

class UrlGenerator {
  public generate(subpath: string): string {
    const path = _removeFirstSlashes(subpath);
    return `${URL_BASE}/${path}`;
  }

  public qrcode(id: string, type: 'public' | 'portal'): string {
    if (process.env.NODE_ENV !== 'production') {
      return `/mocks/qr/${id}-${type}.png`;
    }
    return this.generate(`${PATHES.QRCODE}/${id}/${type}`);
  }

  public upload(subpath: string): string {
    if (process.env.VUE_APP_MOCK_API === 'yes') {
      return 'http://localhost:5000/files/test';
    }
    return this.generate(subpath);
  }
}

export default new UrlGenerator();

// "http://url/"   => "http://url"
// "http://url"    => "http://url"
// "http://url//"  => "http://url"
function _removeEndSlashes(str: string): string {
  const idx = str.lastIndexOf('/');
  return idx === str.length - 1 ? _removeEndSlashes(str.slice(0, idx)) : str;
}

// "/path"     => "path"
// "path"      => "path"
// "///path"   => "path"
function _removeFirstSlashes(str: string): string {
  return str[0] === '/' ? _removeFirstSlashes(str.slice(1)) : str;
}
