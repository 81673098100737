import { useWindowScroll } from '@ravnur/hooks/useWindowScroll';
import { useWindowSize } from '@ravnur/hooks/useWindowSize';
import { computed, defineComponent, PropType } from 'vue';

import Placeholder from '../placeholder/placeholder';

const CN = 'auto-positioning-spinner';

export default defineComponent({
  props: {
    parentRect: { type: Object as PropType<Nullable<DOMRectReadOnly>> },
    withPlaceholder: { type: Boolean, default: false },
  },
  setup(props) {
    const { height: windowHeight } = useWindowSize();
    const { y: scrollTop } = useWindowScroll();

    const styles = computed(() => {
      const parentRect = props.parentRect;
      if (!parentRect) {
        return { display: 'none' };
      }

      if (
        scrollTop.value >= parentRect.top &&
        parentRect.bottom >= scrollTop.value + windowHeight.value
      ) {
        const left = (parentRect.right + parentRect.left) / 2;
        return { top: `50%`, left: `${left}px`, position: 'fixed' };
      }

      const top = Math.max(parentRect.top, scrollTop.value);
      const bottom = Math.min(parentRect.bottom, scrollTop.value + windowHeight.value);

      const middle = (top + bottom) / 2;

      return { top: `${middle - parentRect.top}px`, left: `50%` };
    });

    return () => (
      <>
        {props.withPlaceholder && <Placeholder key="placeholder" data-testid="placeholder" />}
        <spinner key="spinner" class={CN} data-testid="spinner" style={styles.value} />
      </>
    );
  },
});
