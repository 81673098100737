import { $Types } from '@ravnur/core/typings/tsx';
import { Prop } from '@ravnur/decorators';
import { keyById } from '@ravnur/nanoutils/keyBy';
import sortBy from '@ravnur/nanoutils/sortBy';
import FoldersTree from '@ravnur/shared/components/folders-tree/folders-tree';
import LoadableSection from '@ravnur/shared/components/loadable-section/loadable-section';
import { Folder$Node, Folder$Action, Folder$ActionType, Folder } from '@ravnur/shared/types/Folder';
import { Vue } from 'vue-class-component';

import toNodeFormat from '@/transformers/folders/toNodeFormat';

import './my-favorites-folders.scss';

const CN = 'my-favorites-folders';
const sortFolders = sortBy<Folder>((folder) => (folder.isDefault ? '' : folder.name.toLowerCase()));

type Props = {
  actions?: Folder$Action[];
  folder: Nullable<Folder>;
};

type Emits = {
  onAction?: (payload: { action: Folder$ActionType; folder: Folder }) => void;
  onSelect?: (folder: Folder) => void;
};

export default class FavoritesFolders extends Vue {
  declare $props: $Types<Props, Emits, 'folder'>;

  @Prop({ type: Array, default: [] })
  public readonly actions!: Folder$Action[];
  @Prop({ type: Object, default: null })
  public readonly folder!: Nullable<Folder>;
  @Prop({ type: Boolean, default: true })
  public readonly showCreateButton!: boolean;

  private get folders() {
    const list = [...this.store.favoritesFolders.list];
    return sortFolders(list);
  }

  private get nodes() {
    return toNodeFormat(this.folders);
  }

  private get cache() {
    return keyById(this.nodes);
  }

  private get selected() {
    return this.folder ? this.cache[this.folder.id] : null;
  }

  private get isLoading() {
    return this.store.favoritesFolders.loading;
  }

  created() {
    this.store.favoritesFolders.load();
  }

  render() {
    const cn = {
      [CN]: true,
      [`${CN}--loading`]: this.isLoading,
    };

    return (
      <LoadableSection class={cn} loading={this.isLoading}>
        <FoldersTree
          actions={this.actions}
          folders={this.nodes}
          linear={true}
          selected={this.selected}
          onAction={this.doAction}
          onSelect={this.selectNode}
        />
        {this.$slots.default?.()}
      </LoadableSection>
    );
  }

  private selectNode(node: Folder$Node) {
    const folder = this.store.favoritesFolders.cache[node.id];
    this.$emit('select', folder);
    this.$emit('update:folder', folder);
  }

  private doAction(data: { action: Folder$Action; node: Folder$Node }) {
    const folder = this.store.favoritesFolders.cache[data.node.id];
    this.$emit('action', { action: data.action.type, folder });
  }
}
