import { ProcessingInfo } from '@ravnur/processing';
import MockAdapter from 'axios-mock-adapter/types';

import { coinFlip, integer, oneOf } from './casual';
import { startOperation } from './operation';

export default function mocker(mock: MockAdapter) {
  mock.onGet('/api/v1.0/processing').reply(_generate);
  mock.onDelete(/\/api\/v1\.0\/processing\/\d+\/job\/\d+/).reply(_removeJob);
}

function _removeJob() {
  const operation = startOperation(`${Date.now()}`);
  return [200, operation];
}

function _generate() {
  const processing = new Array(integer(3, 1)).fill('').map(_createFakeProcessing);
  return [200, processing];
}

function _createFakeProcessing(): ProcessingInfo {
  const mediaId = integer();
  return {
    id: `${mediaId}`,
    mediaId: `${mediaId}`,
    mediaTitle: `some looooong title for media looooong title for media ${mediaId}`,
    jobs: new Array(integer(4, 1)).fill('').map(_createFakeJob),
  };
}

function _createFakeJob(): any {
  return {
    type: oneOf(['Transcoding']),
    id: `${integer()}`,
    percentage: integer(100),
    isCanceling: coinFlip(),
    needPurgeOnCancelation: false,
  };
}
