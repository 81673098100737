import type { Group } from '@ravnur/shared/types/Group';
import MockAdapter from 'axios-mock-adapter/types';

import { faker } from '@faker-js/faker';
import { _generateGroup, _generateMockList } from './helper-fakers';

const total = +faker.random.numeric(2);
const data = _generateMockList<Group>(total, _generateGroup);

export default function mocker(mock: MockAdapter) {
  mock.onGet('/api/v1.0/group').reply(_load);
}

function _load() {
  return [200, { items: data, total }];
}
