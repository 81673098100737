import { Logger } from '@ravnur/logger';

import { ThumbnailInfo } from '../types/Entity';

const logger = new Logger('FileUploaderHelper');

const FIRST_FILE_IDX = 0;

type UploadData = {
  headers?: Record<string, string | number | undefined>;
  data?: Record<string, string | number | undefined>;
};

export class FileUploadInfo {
  public id = Math.random().toString(36).substr(2);
  public data: { fileExt: string; groupId?: string; mediaId?: string };
  public headers: Record<string, string | number> = {};
  // public progress = '0.00';

  constructor(public action: string, public el: HTMLInputElement) {
    this.action = action;
    this.el = el;
    const parts = (el.value || '').split('.');
    this.data = {
      fileExt: parts.pop() || '',
    };
  }
}

class FileUploaderHelper {
  public getFileFromInfo(info: FileUploadInfo): Nullable<File> {
    const files = info.el.files;
    return files ? files.item(FIRST_FILE_IDX) : null;
  }

  public async upload<T>(info: FileUploadInfo): Promise<T> {
    const file = this.getFileFromInfo(info);

    if (!file) {
      return Promise.reject(new Error('no file'));
    }

    return this.uploadFile(info.action, file, info);
  }

  public async uploadFile<T>(action: string, file: File, info: UploadData = {}): Promise<T> {
    const form = new FormData();
    _objMap(info.data || {}, (key, value) => {
      if (value && typeof value === 'object' && typeof value.toString !== 'function') {
        if (value instanceof File) {
          form.append(key, value, value.name);
        } else {
          form.append(key, JSON.stringify(value));
        }
      } else {
        form.append(key, value);
      }
    });
    form.append('file', file, file.name);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', action);

    return new Promise((resolve, reject) => {
      xhr.onload = listener;
      xhr.onerror = listener;
      xhr.onabort = listener;
      xhr.ontimeout = listener;
      _objMap(info.headers || {}, (key, value) => xhr.setRequestHeader(key, value));
      xhr.send(form);

      function listener(e: Event) {
        const resp = xhr.responseText;
        if (resp) {
          try {
            resolve(JSON.parse(resp));
          } catch (err) {
            logger.error(err);
            return reject(err);
          }
        }
        return reject(e);
      }
    });
  }
}

export default new FileUploaderHelper();

function _objMap(obj: Dictionary<any>, callback: (key: string, value: any) => void) {
  Object.keys(obj).forEach((key: string) => {
    const value = obj[key];
    if (value) {
      callback(key, value);
    }
  });
}

export function isUnUploadedThumbnail(thumbnail: Nullable<ThumbnailInfo>): boolean {
  return thumbnail ? /^data:/.test(thumbnail.url) : false;
}
