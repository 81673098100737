import {
  ExpandedMedia,
  Media$ExternalPage,
  Media$Privacies,
  Media$Types,
  PredefinedMediaProperties,
} from '@ravnur/shared/types/Media';
import { User } from '@ravnur/shared/types/User';

import store from '@/store';
import { Site } from '@/types/Site';

const DEFAULT_MEDIA: Partial<ExpandedMedia> = {
  title: '',
  description: '',
  type: Media$Types.VIDEO,
  duration: 0,
  likes: 0,
  views: 0,
  rating: 0,
  // createdDate: string,
  commentsCount: 0,
  // itemsCount: 0,
  hasCC: false,
  privacy: Media$Privacies.PUBLIC,
  hasMetadata: false,

  allowExternalPage: Media$ExternalPage.NONE,

  // single-mode
  relatedLinks: [],
  sites: [],
  categories: [],
  tags: [],
  // isFollowingOwner: false,
  // iLikeIt: false,
  // inFavorite: false,
  allowedUsers: [],
  groups: [],
  // hasFlaggedByMe: false,

  // fileExtension?: ?string, // document
  // fileSize?: ?string, // document
  // fileUri?: ?string // document

  // audioTrackLang: string,
  // transcriptLang: string,

  isAutoGenerateCaption: false,

  isEnabledDownload: true,
  isEnabledEmbedding: true,

  allowComenting: true,
  allowRating: true,
  hideRelatedMedia: false,

  publishDate: null,
  expirationDate: null,

  notifyAllReviewers: false,
  selectedReviewers: [],

  relatedMedia: [],
  metadata: [],
};

export default function create(media: Partial<ExpandedMedia> = {}): Partial<ExpandedMedia> {
  const site: Site = store.getters['application/getCurrentSite'];
  const owner: User = store.getters['user/getCurrentUser'];
  const predefined: PredefinedMediaProperties =
    store.getters['application/getPredefinedMediaProperties'];
  const sites: string[] = site ? [site.id] : [];
  const audioTrackLang = store.state.user.current.languageId;
  return {
    ...DEFAULT_MEDIA,
    sites,
    owner,
    audioTrackLang,
    ...predefined,
    ...media,
  };
}
