import { useBoundingClientRect } from '@ravnur/hooks/useBoundingClientRect';
import { defineComponent, ref } from 'vue';

import AutoPositioningSpinner from './auto-positioning-spinner';

import './loadable-section.scss';

const CN = 'loadable-section';

export default defineComponent({
  props: {
    loading: { type: Boolean, required: true },
    withPlaceholder: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    const div = ref<HTMLDivElement>();
    const rect = useBoundingClientRect(div);

    return () => (
      <div ref={div} class={CN}>
        {ctx.slots.default?.()}
        {props.loading && (
          <AutoPositioningSpinner parentRect={rect.value} withPlaceholder={props.withPlaceholder} />
        )}
      </div>
    );
  },
});
