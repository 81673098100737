import { Ref, ref } from 'vue';

import { useResizeObserver } from './useResizeObserver';

export function useBoundingClientRect(target: Ref<Element | undefined>) {
  const rect = ref<DOMRectReadOnly | null>(null);

  useResizeObserver(target, (entries) => {
    const entry = entries[0];
    if (target.value) {
      rect.value = target.value.getBoundingClientRect().toJSON();
    } else {
      rect.value = entry.contentRect.toJSON();
    }
  });

  return rect;
}
